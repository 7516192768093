import 'helioscope/app/singleline/async/directives';
import 'helioscope/app/apollo';
import 'helioscope/app/apollo/directives';
import Logger from 'js-logger';

import { user } from 'helioscope/app/users/auth';
import { helioscopeConfig } from 'helioscope/app/config';

import { $q, Messager } from 'helioscope/app/utilities/ng';
import { loadClipper } from 'helioscope/app/utilities/geometry/clipper';
import { loadGoogleMaps } from 'helioscope/app/utilities/maps';

import { Profile } from 'helioscope/app/users';
import { WeatherSource } from 'helioscope/app/libraries';

import { DesignDispatcher, modalPrompt } from './events';
import { Design } from './Design';

import * as fs from './field_segment';
import * as wz from './wiring_zone';
import * as ko from './keepout';
import * as shading from './shading';
import * as overlays from './overlays';
import * as paywall from './paywall';
import * as premade from './premade';
import * as controllers from './controllers';
import * as directives from './directives';
import * as persistenceDirectives from './persistence/directives';


const logger = Logger.get('designer');


angular.module(
    'helioscope.designer',
    [
        'helioscope.designer.config',
        'helioscope.designer.directives',
        'helioscope.designer.controllers',
        'helioscope.designer.paywall',
        'helioscope.designer.resources',
        'helioscope.designer.overlays',
    ],
);

// these controllers are included in templates, so need to be officially registered with angular
angular.module('helioscope.designer.controllers', [])
    .controller('FixedTiltRackController', fs.FixedTiltRackController)
    .controller('PopoverModifyCtrl', fs.PopoverModifyCtrl)
    .controller('TimeOfDayShadeCtrl', fs.TimeOfDayShadeCtrl)
    .controller('KeepoutShadeCtrl', ko.KeepoutShadeCtrl)
    .controller('DcAcRatioPopoverCtrl', wz.DcAcRatioPopoverCtrl);


// backwards compatibility while we shift to modules
angular.module('helioscope.designer.resources', [])
    .factory('Design', () => Design)
    .factory('FieldSegment', () => fs.FieldSegment)
    .factory('Keepout', () => ko.Keepout)
    .factory('WiringZone', () => wz.WiringZone);

angular.module('helioscope.designer.directives', [])
    .directive('animateOnChange', directives.animateOnChange)
    .directive('updateAcConfig', directives.updateAcConfig)
    .directive('updateModuleCharacterizations', directives.updateModuleCharacterizations)
    .directive('stateForm', persistenceDirectives.stateForm)
    .directive('stateModel', persistenceDirectives.stateModel)
    .directive('undoButtons', persistenceDirectives.undoButtons)
    .directive('undo', persistenceDirectives.undo)
    .directive('redo', persistenceDirectives.redo)
    .directive('saveButton', persistenceDirectives.saveButton);


const mod = angular.module(
    'helioscope.designer.config',
    [
        'ui.router',
        'ui.bootstrap.tooltip',
        'helioscope.users.auth',
        'helioscope.libraries.resources',
        'helioscope.users.logos',
        'helioscope.singleline.directives',
        'helioscope.apollo.directives',
    ],
);


mod.controller('RemoveIndependentTiltConfirmationModalCtrl', ($scope, $modalInstance, user, fsCtrl) => {
    $scope.user = user;
    $scope.close = () => $modalInstance.close();
    $scope.removeIndependentTiltFromFieldSegment = () => {
        fsCtrl.removeIndependentTilt();
        $modalInstance.close();
    }
});


mod.factory('confirmRemoveIndependentTiltDlg', ($modal) => {
    const opts = {
        templateUrl: require('helioscope/app/designer/field_segment/partials/remove_independent_tilt_modal.html'),
        windowClass: 'remove-independent-tilt-modal',
        controller: 'RemoveIndependentTiltConfirmationModalCtrl',
        resolve: {
            user: ['Authenticator', (Authenticator) => Authenticator.user()],
        },
    };

    return (fsCtrl) => {
        opts.resolve.fsCtrl = () => fsCtrl;
        return $modal.open(opts);
    };
});


mod.controller('independentTiltContactAdminModalCtrl', ($scope, $modalInstance, user) => {
    $scope.user = user;
    $scope.close = () => $modalInstance.close();
    $scope.removeIndependentTiltFromFieldSegment = () => {
        $modalInstance.close();
    }
});

mod.factory('independentTiltContactAdminDlg', ($modal) => {
    const opts = {
        templateUrl: require('helioscope/app/designer/field_segment/partials/contact_admin_independent_tilt_modal.html'),
        windowClass: 'contact-admin-independent-tilt-modal',
        controller: 'independentTiltContactAdminModalCtrl',
        resolve: {
            user: ['Authenticator', (Authenticator) => Authenticator.user()],
        },
    };

    return () => {
        return $modal.open(opts);
    };
});


function designProvider($stateParams) {
    return Design.get($stateParams).$promise
        .catch(resp => {
            logger.error(resp);
            return $q.reject({ type: resp.status, message: 'Could not load the design' });
        })
        .then(design => {
            if (design.to_delete) {
                return $q.reject({ type: 404, message: 'Design has been deleted.' });
            }

            return design;
        });
}

mod.config(($stateProvider, accessLevels, $tooltipProvider) => {
    $tooltipProvider.options({
        placement: 'right',
        appendToBody: true,
        trigger: 'focus',
        popupDelay: '250',
    });

    $stateProvider
        .state('designer', {
            url: '/designer',
            template: '<div ui-view></div>',
            abstract: true,
            resolve: {
                user: (Authenticator) => Authenticator.authorize(accessLevels.user),
                clipper: loadClipper,

                // need to preload component libraries so we can automatically
                // generate and parse component trees
                favoriteModules: (Module) => Module.project_modules().$promise,
                acConfigs: (acConfigLibrary) => acConfigLibrary(),
                wires: (wireLibrary) => wireLibrary(),
            },
        })

        // eliminate this and s/designer.design./designer./g
        .state('designer.design', {
            url: '/{design_id:[0-9]+}',
            views: {
                '': {
                    templateUrl: require('helioscope/app/designer/partials/designer.design.html'),
                    controller: controllers.DesignCtrl,
                    controllerAs: 'designCtrl',
                },
                'nav-menu@': {
                    templateUrl: require('helioscope/app/designer/partials/designer.design.navbar.html'),
                    controller: controllers.DesignMenuCtrl,
                    controllerAs: 'designMenuCtrl',
                },
            },
            resolve: {
                googleMapsLib: () => {
                    const mapsPromise = loadGoogleMaps();
                    // wait for google maps to load if in PDF Mode (because 2d Designer still
                    // needs it), otherwise load but don't wait
                    return helioscopeConfig.pdf_mode ? mapsPromise : null;
                },
                // HACK: design needs clipper to initialize the design scene are implicitly used internally
                // eslint-disable-next-line no-unused-vars
                design: ($stateParams, clipper) => designProvider($stateParams).then(design => {
                    design.initializeDesignScene();
                    return design;
                }),
                defaultProfiles: (design) => {
                    const args = {};

                    if (design.project.profile_id) {
                        args.project_id = design.project.project_id;
                    } else if (user.default_profile_id) {
                        args.profile_id = user.default_profile_id;
                    }

                    return Profile.defaults(args).$promise.then(profile => profile.loadDependencies(args));
                },

                // HACK: wires are implicitly used internally
                dispatcher: ['design', 'wires', async function dispatcher(design, _clipper, _wires) {
                    const designer = new DesignDispatcher(design, {
                        pdfSettings: helioscopeConfig.pdf_mode && user.preferences.design_renders,
                        designMutable: true,
                    });
                    const { unmatchedModules } = await designer.loadComponents();
                    if (unmatchedModules.length > 0 && !helioscopeConfig.pdf_mode && !design.locked) {
                        logger.warn(`Design had ${unmatchedModules} unmatched modules`);
                        // await createDesignBackup(design, unmatchedModules);
                    }

                    return designer;
                }],
            },
            onExit: (design, dispatcher) => {
                dispatcher.cleanup();
            },
            data: {
                helpOverlayUrl: 'helioscope/app/designer/partials/designer.design.help.html',
            },
        })
        .state('designer.design.preferences', {
            url: '/preferences',
            templateUrl: require('helioscope/app/designer/partials/designer.design.preferences.html'),
            controller: controllers.DesignerPreferencesCtrl,
            controllerAs: 'prefsCtrl',
            resolve: {
                moduleCharacterizations: (ModuleCharacterization, design) => {
                    const moduleCharacterizations = {};
                    // we don't just do one query for project_id, because there may be unsaved field segments
                    const moduleIds = [...new Set(design.field_segments.map(seg => seg.module_id))];
                    // eslint-disable-next-line camelcase
                    const promises = moduleIds.map((module_id) => ModuleCharacterization.query({
                        module_id,
                        project_id: design.project_id,
                    }).$promise.then((mc) => {
                        moduleCharacterizations[module_id] = mc.map(char => char.module_characterization_id);
                    }));
                    return Promise.all(promises).then(() => moduleCharacterizations);
                },
            },
        })
        .state('designer.design.lidar', {
            url: '/lidar',
            templateUrl: require('helioscope/app/designer/partials/designer.design.lidar.html'),
            controller: controllers.DesignerLidarSettingsCtrl,
            controllerAs: 'lidarSettingsCtrl',
        })
        .state('designer.design.field_segments', {
            url: '/field_segments',
            templateUrl: require('helioscope/app/designer/field_segment/partials/list.html'),
            controller: fs.FieldSegmentListCtrl,
            controllerAs: 'fsCtrl',
            resolve: {
                profile: (defaultProfiles) => defaultProfiles.mechanical,
            },
        })
        .state('designer.design.field_segments.detail', {
            url: '/{field_segment_id:[0-9]+}',
            templateUrl: require('helioscope/app/designer/field_segment/partials/detail.html'),
            controller: fs.FieldSegmentCtrl,
            controllerAs: 'fsDetailCtrl',
            resolve: {
                fieldSegment: (design, $stateParams, $state) => {
                    const fieldSegment = fs.FieldSegment.cached($stateParams.field_segment_id);
                    if (_.get(fieldSegment, 'design_id') === design.design_id) {
                        return fieldSegment;
                    }

                    Messager.error('Could not find that field segment');
                    $state.go('designer.design.field_segments', design);
                    return null;
                },
            },
            data: {
                helpOverlayUrl: 'helioscope/app/designer/field_segment/partials/detail.help.html',
            },
            onEnter: (dispatcher, fieldSegment) => {
                dispatcher.selectEntity(fieldSegment);
            },
            onExit: (dispatcher) => {
                if (dispatcher.selectedEntity instanceof fs.FieldSegment && dispatcher.selectedEntities.size === 1) {
                    dispatcher.clearSelection();
                }
            },
        })
        .state('designer.design.field_segments.new', {
            url: '/new',
            templateUrl: require('helioscope/app/designer/field_segment/partials/new.html'),
            controller: fs.NewFieldSegmentCtrl,
            controllerAs: 'newFsCtrl',
            data: {
                helpOverlayUrl: 'helioscope/app/designer/field_segment/partials/new.help.html',
            },
        })
        .state('designer.design.keepouts', {
            url: '/keepouts',
            templateUrl: require('helioscope/app/designer/keepout/partials/list.html'),
            controller: ko.KeepoutsCtrl,
            controllerAs: 'koCtrl',
            data: {
                helpOverlayUrl: 'helioscope/app/designer/keepout/partials/list.help.html',
            },
        })
        .state('designer.design.keepouts.new', {
            url: '/new',
            templateUrl: require('helioscope/app/designer/keepout/partials/new.html'),
            controller: ko.NewKeepoutsCtrl,
            controllerAs: 'newKoCtrl',
        })
        .state('designer.design.keepouts.edit', {
            url: '/{keepout_id:[0-9]+}',
            templateUrl: require('helioscope/app/designer/keepout/partials/edit.html'),
            controller: ko.EditKeepoutsCtrl,
            controllerAs: 'editKoCtrl',
            resolve: {
                keepout: (design, $stateParams, $state) => {
                    const keepout = ko.Keepout.cached($stateParams.keepout_id);

                    if (_.get(keepout, 'design_id') === design.design_id) {
                        return keepout;
                    }
                    Messager.error('Could not find that keepout');
                    $state.go('designer.design.keepouts', design);
                    return null;
                },
            },
            onEnter: (dispatcher, keepout) => dispatcher.selectEntity(keepout),
            onExit: (dispatcher, keepout) => {
                if (dispatcher.selectedEntity === keepout) dispatcher.selectEntity();
            },
        })
        .state('designer.design.combinedkeepouts', {
            url: '/combinedkeepouts',
            templateUrl: require('helioscope/app/designer/keepout/partials/combined.html'),
            controller: ko.CombinedKeepoutsCtrl,
            controllerAs: 'koCtrl',
            data: {
                helpOverlayUrl: require('helioscope/app/designer/keepout/partials/list.help.html'),
            },
            params: {
                entity_premade_id: null,
                keepout_id: null,
            },
        })
        .state('designer.design.wiring_zones', {
            url: '/wiring_zones?advanced',
            templateUrl: require('helioscope/app/designer/wiring_zone/partials/list.html'),
            controller: wz.WiringZoneCtrl,
            controllerAs: 'wzCtrl',
            resolve: {
                profile: (defaultProfiles) => defaultProfiles.electrical,
            },

            onExit: (dispatcher) =>  dispatcher.setShowWiring(false),
            onEnter: (design, $state, $stateParams, dispatcher) => {
                dispatcher.setShowWiring(true);

                // current state information is not available until after the call stack has been
                // yielded, unfortunately
                _.defer(() => {
                    if (!$state.is('designer.design.wiring_zones')) {
                        return;
                    }
                    if (!$stateParams.advanced && design.wiring_zones.length === 1) {
                        $state.go('designer.design.wiring_zones.detail', design.wiring_zones[0]);
                    }
                });
            },
            data: {
                helpOverlayUrl: 'helioscope/app/designer/wiring_zone/partials/list.help.html',
            },
        })
        .state('designer.design.wiring_zones.detail', {
            url: '/{wiring_zone_id:[0-9]+}',
            templateUrl: require('helioscope/app/designer/wiring_zone/partials/detail.html'),
            controller: wz.WiringZoneDetailCtrl,
            controllerAs: 'wzDetailCtrl',
            resolve: {
                wiringZone: (design, $stateParams, $state) => {
                    const wiringZone = wz.WiringZone.cached($stateParams.wiring_zone_id);

                    if (_.get(wiringZone, 'design_id') === design.design_id) {
                        return wiringZone;
                    }
                    Messager.error('Could not find that wiring zone');
                    $state.go('designer.design.wiring_zones', design);
                    return null;
                },
            },
            data: {
                helpOverlayUrl: 'helioscope/app/designer/wiring_zone/partials/detail.help.html',
            },
        })
        .state('designer.design.wiring_zones.detail.dc', {
            url: '/dc',
            templateUrl: require('helioscope/app/designer/wiring_zone/partials/detail.dc.html'),
        })
        .state('designer.design.wiring_zones.detail.ac', {
            url: '/ac',
            templateUrl: require('helioscope/app/designer/wiring_zone/partials/detail.ac.html'),
        })
        .state('designer.design.overlays', {
            url: '/overlays',
            templateUrl: require('helioscope/app/designer/overlays/partials/index.html'),
            controller: overlays.OverlaysDesignerCtrl,
            controllerAs: 'overlaysDesignerCtrl',
            data: {
                helpOverlayUrl: 'helioscope/app/designer/overlays/partials/index.help.html',
            },
        })
        .state('designer.design.shade_optimization', {
            url: '/shade_optimization',
            templateUrl: require('helioscope/app/designer/shading/partials/shade_optimization.html'),
            controller: shading.ShadeOptimizationCtrl,
            controllerAs: 'shadeCtrl',
            resolve: {
                weatherSources: (design) => WeatherSource.query({
                    project_id: design.project_id,
                    limit: 5,
                    load_datasets: true,
                    only_tmy_datasets: true,
                }).$promise,
            },
            onEnter: (dispatcher) => {
                dispatcher.setShowShading(true);
            },
            onExit: (design, dispatcher) => {
                design.field_segments.map(fieldSegment => dispatcher.renderer.renderModules(fieldSegment));
                dispatcher.setShowShading(false);
            },
        })

        .state('designer.design.premades', {
            url: '/premades',
            templateUrl: require('helioscope/app/designer/premade/partials/list.html'),
            controller: premade.PremadeCtrl,
            controllerAs: 'premadeCtrl',
        })

        .state('designer.3d_renders', {
            url: '/renders/{design_id:[0-9]+}',
            views: {
                '': {
                    templateUrl: require('helioscope/app/designer/partials/designer.3d_renders.html'),
                    controller: controllers.DesignCtrl,
                    controllerAs: 'designCtrl',
                },
            },
            resolve: {
                googleMapsLib: () => {
                    const mapsPromise = loadGoogleMaps();
                    // wait for google maps to load if in PDF Mode (because 2d Designer still
                    // needs it), otherwise load but don't wait
                    return helioscopeConfig.pdf_mode ? mapsPromise : null;
                },
                // HACK: design needs clipper to initialize the design scene are implicitly used internally
                // eslint-disable-next-line no-unused-vars
                design: ($stateParams, clipper) => designProvider($stateParams).then(design => {
                    design.initializeDesignScene();
                    return design;
                }),
                defaultProfiles: (design) => {
                    const args = {};

                    if (design.project.profile_id) {
                        args.project_id = design.project.project_id;
                    } else if (user.default_profile_id) {
                        args.profile_id = user.default_profile_id;
                    }

                    return Profile.defaults(args).$promise.then(profile => profile.loadDependencies(args));
                },

                // HACK: wires are implicitly used internally
                dispatcher: ['design', 'wires', async function dispatcher(design, _clipper, _wires) {
                    const designer = new DesignDispatcher(design, {
                        pdfSettings: helioscopeConfig.pdf_mode && user.preferences.design_renders,
                        designMutable: true,
                    });
                    const { unmatchedModules } = await designer.loadComponents();
                    if (unmatchedModules.length > 0 && !helioscopeConfig.pdf_mode && !design.locked) {
                        logger.warn(`Design had ${unmatchedModules} unmatched modules`);
                        // await createDesignBackup(design, unmatchedModules);
                    }

                    return designer;
                }],
            },
            onExit: (design, dispatcher) => {
                dispatcher.cleanup();
            },
        })
        .state('designer.site_plan', {
            url: '/site_plan/{design_id:[0-9]+}',
            views: {
                '': {
                    templateUrl: require('helioscope/app/siteplan/partials/siteplan.html'),
                    controller: controllers.SitePlanCtrl,
                    controllerAs: 'sitePlanCtrl',
                },
                'nav-menu@': {
                    templateUrl: require('helioscope/app/designer/partials/designer.design.navbar.html'),
                    controller: controllers.DesignMenuCtrl,
                    controllerAs: 'designMenuCtrl',
                },
            },
            resolve: {
                design: ($stateParams) => designProvider($stateParams),
                // HACK: clipper is implicitly used internally
                dispatcher: ['design', 'clipper', async function dispatcher(design, _clipper) {
                    const designer = new DesignDispatcher(design, {
                        pdfSettings: helioscopeConfig.pdf_mode && user.preferences.design_renders,
                        designMutable: false,
                        showWiring: true,
                    });

                    await designer.loadComponents();
                    return designer;
                }],
            },
            onEnter: () => {
            },
            onExit: (design, dispatcher) => {
                dispatcher.cleanup();
            },
        })
        .state('designer.design.bulk_actions_panel', {
            url: '/bulk_actions_panel',
            templateUrl: require('helioscope/app/designer/partials/designer.design.bulk_actions_panel.html'),
            controller: controllers.BulkActionsPanelCtrl,
            controllerAs: 'bulkActionsPanelCtrl',
            onExit: (dispatcher) => {
                if (dispatcher.selectedEntities && dispatcher.selectedEntities.size !== 1) {
                    dispatcher.clearSelection();
                }
            },
        });
});
