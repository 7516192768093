import Logger from 'js-logger';

import { round, get, filter, find } from 'lodash';
import moment from 'moment';

import * as analytics from 'helioscope/app/utilities/analytics';
import { getChat } from 'helioscope/app/utilities/chat-wrapper';
import { Authenticator, Messager, $modal, $state, $stateParams } from 'helioscope/app/utilities/ng';
import { getBetaBillingURL } from 'helioscope/app/utilities/url';
import { helioscopeConfig } from 'helioscope/app/config';

import { user as loggedInUser, User } from 'helioscope/app/users';

import { invoiceStatus, Subscription } from
    './Subscription';


const logger = Logger.get('subscriptions/controllers');

const DEFAULT_PROJECTS_PER_LICENSE_PER_MONTH = 10;

export class EditSubscriptionCtrl {
    constructor($scope) {
        'ngInject';

        this.scope = $scope;
        this.subscription = $scope.subscription;
        this.invoiceStatus = invoiceStatus;
        this.loading = false;
        this.isAdmin = loggedInUser.is_admin || loggedInUser._masquerading_admin;
    }
}


export class AccountBillingCtrl {
    constructor($scope, allV1Subscriptions) {
        'ngInject';

        this.$scope = $scope;

        this.allV1Subscriptions = allV1Subscriptions;
        this.showAllV1Subscriptions = false;

        // subscriptions with terminal status cannot be actioned on
        this.terminalStatuses = ['canceled', 'incomplete_expired'];

        this.betaBillingURL = loggedInUser.email !== $scope.currentUser.email
            ? getBetaBillingURL($scope.user(), $scope.currentUser.email, {})
            : getBetaBillingURL($scope.user(), '', {});

        this.canceledV1Subscriptions = (
            _(allV1Subscriptions)
                .filter(sub => this.terminalStatuses.includes(sub.status))
                .sortBy('current_period_end')
                .reverse()
                .value()
        );
    }
}


export class PaymentCtrl {
    constructor($scope, subscription) {
        'ngInject';

        $scope.subscription = subscription;

        if ($state.is('payments')) {
            // if no invoice specified get the most recent unpaid invoice
            // make paid invoices always sorted before unpaid invoices
            // regardless of time
            const latestInvoice = _(subscription.invoices).sortBy(
                (i) => (new Date(i.created)).getTime() / (i.paid === true ? 10000000 : 1),
            ).last();

            $state.go('payments.invoice', { invoice_external_id: latestInvoice.external_id }, { location: 'replace' });
        }
    }
}


export class PaymentInvoiceCtrl {
    constructor($scope, invoice) {
        'ngInject';

        const { subscription } = $scope;

        $scope.invoice = invoice;
        $scope.invoiceStatus = invoiceStatus;

        // 11-29-22: Don't show the invoice view if the subscription is closed before 11-15-22 and out of band.
        // Those invoices render w/ new base price change values rather than maintaining old values, so users will
        // be directed to download the cached invoice PDF.
        $scope.showInvoice = () => {
            const rolloverDate = moment('2022-11-15');
            if (rolloverDate.isAfter(invoice.created)) {
                return (!invoice.closed && invoice.total === subscription.total) || !!invoice.stripe_id;
            }
            return true;
        };
    }
}
