import { $log, Messager } from 'helioscope/app/utilities/ng';
import { EntityPremade } from 'helioscope/app/designer/premade/Premade';
import { interactGroundPoint } from 'helioscope/app/apollo/InteractHelpers';
import { createFieldSegmentCopy, FieldSegment, FieldSegmentActionsMixin } from 'helioscope/app/designer/field_segment';
import { createKeepoutCopy, Keepout, KeepoutActionsMixin } from 'helioscope/app/designer/keepout';
import { actionPremadePaste } from 'helioscope/app/designer/premade/actions';
import _, { forEach } from 'lodash';
import { MapConfig } from './MapConfig';
import { BulkObjects } from 'helioscope/app/designer/persistence/BulkObjects';
import * as Geometry from 'helioscope/app/utilities/geometry';
import { func } from 'prop-types';

export function bulkChangeConfig(resource, dispatcher) {
    const selectionSize = resource.length;
    return {
        delete: {
            text: `Remove group with ${selectionSize} objects`,
            preflight: () => { },
            onSuccess: (bulkObjects) => {
                bulkObjects.handleDeleteOnSuccess(dispatcher);
                Messager.success(`Successfully deleted ${selectionSize} objects`);
            },
            onError: (err) => {
                resource.handleDeleteOnError(dispatcher);
                Messager.error(`Error when deleting ${selectionSize} objects`);
            },
        },
        create: {
            text: `Create group with ${selectionSize} objects`,
            preflight: (bulkObjects) => {
                bulkObjects.handleCreatePreflight(dispatcher);
            },
            onSuccess: (bulkObjects) => {
                bulkObjects.handleCreateOnSuccess(dispatcher);
                Messager.success(`Successfully created ${selectionSize} objects`);
            },
            onError: (err) => {
                resource.handleCreateOnError(dispatcher);
                $log.warn(err);
                Messager.error(`Error when creating ${selectionSize} objects`);
            },
        },
        update: {
            text: `Update group with ${selectionSize} objects`,
            preflight: (bulkObjects) => {
                bulkObjects.handleUpdatePreflight(dispatcher);
            },
            onSuccess: (bulkObjects) => {
                bulkObjects.handleUpdateOnSuccess(dispatcher);
                Messager.success(`Successfully updated ${selectionSize} objects`);
            },
            onError: (err) => {
                resource.handleUpdateOnError(dispatcher);
                Messager.error(`Error when updating ${selectionSize} objects`);
            },
        }
    };
}

export class DesignActionsMixin {
    constructor(dispatcher, design) {
        // technically don't need to include the design here, but it keeps the pattern clean
        this.design = design;
        this.dispatcher = dispatcher;
    }

    toggleKeepoutsFromShade({ dispatcher = this.dispatcher, design = this.design } = {}) {
        const useKeepoutShading = design.shade_keepouts;
        const noShadeMessage = 'Remove modules from keepout shade';
        const allowShadeMessage = 'Allow modules in keepout shade';

        dispatcher.createSinglePropertyChange({
            resource: design,
            path: 'shade_keepouts',
            oldVal: !useKeepoutShading,
            newVal: useKeepoutShading,
            mergeable: false,
            loadMessage: useKeepoutShading ? noShadeMessage : allowShadeMessage,
            rollbackMessage: useKeepoutShading ? allowShadeMessage : noShadeMessage,
            callback: () => dispatcher.designManager.updateDesignShading(),
        });
    }

    addInterconnect({ dispatcher = this.dispatcher, design = this.design, location } = {}) {
        let targetLocation;
        if (!location) {
            targetLocation = design.defaultPccLocation();
        } else {
            targetLocation = location;
        }

        dispatcher.createSinglePropertyChange({
            resource: design,
            path: 'geometry.pcc_location',
            oldVal: null,
            newVal: targetLocation,
            mergeable: false,
            loadMessage: 'Add AC Wiring to Design',
            rollbackMessage: 'Remove AC Wiring from Design',
            callback: () => dispatcher.renderUpdater.updateWiringDeferred(),
        });
    }

    removeInterconnect({ dispatcher = this.dispatcher, design = this.design } = {}) {
        dispatcher.createSinglePropertyChange({
            resource: design,
            path: 'geometry.pcc_location',
            oldVal: design.geometry.pcc_location,
            newVal: null,
            mergeable: false,
            loadMessage: 'Remove AC Wiring from Design',
            rollbackMessage: 'Add AC Wiring to Design',
            callback: () => dispatcher.renderUpdater.updateWiringDeferred(),
        });
    }

    deleteSelectedEntities() {
        // Single deletes are handled in FieldSegmentActionsMixin and CombinedKeepoutsCtrl
        if (this.dispatcher.selectedEntities.size < 2) return;

        const resource = new BulkObjects(Array.from(this.dispatcher.selectedEntities.values()));
        this.dispatcher.stateHandler.deleteObject(resource, bulkChangeConfig(resource, this.dispatcher));
    }
}


export const designActions = new DesignActionsMixin();


export const DESIGN_MAP_ACTIONS = {
    'Interconnect:dragend': (dispatcher, { interconnect, location, design }) => {
        interconnect.setLocation(location);

        dispatcher.createSinglePropertyChange({
            resource: design,
            path: 'geometry.pcc_location',
            oldVal: design.geometry.pcc_location,
            newVal: location,
            mergeable: false,
            loadMessage: 'Move PCC Location',
            rollbackMessage: 'Undo PCC Location Move',
            callback: async (_design, _path, value) => {
                dispatcher.renderUpdater.updateWiringDeferred();

                design.project.geometry.pcc_location = value;
                await design.project.$update();
                $log.info('Updated project PCC Location');
            },
        });

        dispatcher.renderUpdater.updateWiring();
    },
    tileLayerChange: (dispatcher, { tileLayer, design }) => {
        if (tileLayer === design.geometry.tile_layer) {
            return;
        }

        design.geometry.tile_layer = tileLayer;

        // schedule design save immediately rather than debouncinging it
        // sometimes this save may be scheduled behind all the tiles being loaded
        // causing noticeable interface lags on route changes (because route changes
        // will wait for the updateQueue to flush before proceeeding).
        dispatcher.stateHandler.updateQueue.schedule(design, { now: true });
    },
};


export class PasteAction {
    constructor(dRenderer) {
        this.renderer = dRenderer;
        this.dispatcher = dRenderer.dispatcher;
    }

    _pasteEntity(entity, groundPoint) {
        if (entity instanceof FieldSegment) {
            const fsMixin = new FieldSegmentActionsMixin(this.dispatcher, entity);
            fsMixin.paste({ fieldSegment: entity, dispatcher: this.dispatcher, groundPoint: groundPoint });
        } else if (entity instanceof Keepout) {
            const koMixin = new KeepoutActionsMixin(this.dispatcher, entity);
            koMixin.paste({ keepout: entity, dispatcher: this.dispatcher, groundPoint: groundPoint });
        } else if (entity instanceof EntityPremade) {
            actionPremadePaste({ dispatcher: this.dispatcher, premade: entity, position: groundPoint });
        }
    }

    _pasteEntities(selectedEntities, groundPoint) {
        const pastePositions = calculateRelativePositionToCursor(selectedEntities, groundPoint);
        let entitiesToCopy = [];
        for (let i = 0; i < selectedEntities.length; i++) {
            const positionShift = new Geometry.Vector(
                pastePositions[i].x - selectedEntities[i].centroid().x,
                pastePositions[i].y - selectedEntities[i].centroid().y
            );
            if (selectedEntities[i] instanceof EntityPremade) {
                const premadeCopy = this._copyPremade(selectedEntities[i], positionShift);
                entitiesToCopy.push(premadeCopy);
            } else if (selectedEntities[i] instanceof FieldSegment) {
                const fieldSegmentCopy = createFieldSegmentCopy(selectedEntities[i]);
                fieldSegmentCopy.move(positionShift, { shiftPath: true });
                entitiesToCopy.push(fieldSegmentCopy);
            } else if (selectedEntities[i] instanceof Keepout) {
                const keepoutCopy = createKeepoutCopy(selectedEntities[i]);
                keepoutCopy.move(positionShift, { shiftPath: true });
                entitiesToCopy.push(keepoutCopy);
            }
        }
        const bulkObjects = new BulkObjects(entitiesToCopy);
        this.dispatcher.stateHandler.createObject(bulkObjects, bulkChangeConfig(bulkObjects, this.dispatcher));
    }

    _copyPremade(premade, positionShift) {
        const geometry = _.cloneDeep(premade.geometry);
        geometry.parameters.position = {
            x: premade.geometry.parameters.position.x + positionShift.x,
            y: premade.geometry.parameters.position.y + positionShift.y,
            z: premade.geometry.parameters.position.z
        };
        return new EntityPremade({
            design_id: this.dispatcher.design.design_id,
            geometry,
            description: `Spherical Tree ${this.dispatcher.design.entity_premades.length + 1}`,
        });
    }

    pasteToCursor(pt) {
        const groundPoint = interactGroundPoint(this.renderer, pt);
        const selectedEntities = this.dispatcher.internalClipboard.readFromClipboard();

        if (selectedEntities.length === 0) {
            this.dispatcher.deactivatePasteMode();
            return;
        }

        if (selectedEntities.length > 1) {
            this._pasteEntities(selectedEntities, groundPoint);
        } else {
            this._pasteEntity(selectedEntities[0], groundPoint);
        }

        this.dispatcher.deactivatePasteMode();
    }
}

export function calculateRelativePositionToCursor(selectedEntities, groundPoint) {
    const pastePositions = [];

    const centroid = selectedEntities.reduce((acc, entity) => {
        const entityCentroid = entity.centroid();
        acc.x += entityCentroid.x;
        acc.y += entityCentroid.y;
        return acc;
    }, { x: 0, y: 0 });

    centroid.x /= selectedEntities.length;
    centroid.y /= selectedEntities.length;

    const relativePositions = selectedEntities.map(entity => {
        const entityCentroid = entity.centroid();
        return {
            x: entityCentroid.x - centroid.x,
            y: entityCentroid.y - centroid.y,
        };
    });

    relativePositions.forEach(relativePosition => {
        const newPosition = {
            x: groundPoint.x + relativePosition.x,
            y: groundPoint.y + relativePosition.y,
        };
        pastePositions.push(newPosition);
    });

    return pastePositions;
}
