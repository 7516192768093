import * as THREE from 'three';
import { LineSegment2 } from 'helioscope/app/utilities/geometry/geo2';
import { CursorConfig } from '../../designer/CursorConfig';
import {
    containerPoint,
    interactGroundPoint,
} from '../InteractHelpers';
import {
    getFourPoints,
    applyRotationToFourPoints,
    getCenterPoint,
} from './OverlayHelpers';

export class DragActionImageOverlayMove {
    constructor(dRenderer, overlay, event) {
        this.dRenderer = dRenderer;
        this.overlay = overlay;
        this.overlay_parameter = overlay.overlay_parameter;
        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);
        this.downPoint = gp;
    }

    dragMouseMove(event) {
        this.dRenderer.setCursorStyle(CursorConfig.MOVE);
        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);

        const delta = (new THREE.Vector2()).subVectors(gp, this.downPoint);
        const delta3d = new THREE.Vector3(delta.x, delta.y, 0);

        if (!this.quadPoints) {
            this.quadPoints = getFourPoints(this.overlay, false);
        }

        for (const point of this.quadPoints) {
            point.add(delta3d);
        }

        this.overlay.overlay_parameter = {
            quad_points: this.quadPoints,
            opacity: this.overlay_parameter.opacity,
            rotation_angle: this.overlay_parameter.rotation_angle,
        };

        this.dRenderer.renderOverlay({
            overlay: this.overlay,
            options: {
                limitOpacity: true,
                renderOnTop: true,
            },
        });

        this.downPoint = gp;
    }

    dragMouseUp() {
        this.dRenderer.setCursorStyle(CursorConfig.DEFAULT);
        if (this.quadPoints) {
            this.dRenderer.dispatcher.createSinglePropertyChange({
                resource: this.overlay,
                path: 'overlay_parameter',
                oldVal: this.overlay_parameter,
                newVal: {
                    quad_points: this.quadPoints,
                    opacity: this.overlay_parameter.opacity,
                    rotation_angle: this.overlay_parameter.rotation_angle,
                },
                enableUndo: true,
                loadMessage: `Redo Overlay move for ${this.overlay.filename}`,
                rollbackMessage: `Undo Overlay move for ${this.overlay.filename}`,
            });
        }
    }
}

export class DragActionImageOverlayEdgeEdit {
    constructor(dRenderer, overlay, index, event) {
        this.dRenderer = dRenderer;
        this.overlay = overlay;
        this.overlay_parameter = overlay.overlay_parameter;
        this.index = index;
        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);
        this.downPoint = gp;
    }

    dragMouseMove(event) {
        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);

        const quadPoints = this.getNewPositions(this.index, getFourPoints(this.overlay), gp);

        // Rotated it back
        this.quadPoints = applyRotationToFourPoints(quadPoints, -1 * this.overlay_parameter.rotation_angle);
        this.overlay.overlay_parameter = {
            quad_points: this.quadPoints,
            opacity: this.overlay_parameter.opacity,
            rotation_angle: this.overlay_parameter.rotation_angle,
        };

        this.dRenderer.renderOverlay({
            overlay: this.overlay,
            options: {
                limitOpacity: true,
                renderOnTop: true,
            },
        });

        this.downPoint = gp;
    }

    dragMouseUp() {
        if (this.quadPoints) {
            this.dRenderer.dispatcher.createSinglePropertyChange({
                resource: this.overlay,
                path: 'overlay_parameter',
                oldVal: this.overlay_parameter,
                newVal: {
                    quad_points: this.quadPoints,
                    opacity: this.overlay_parameter.opacity,
                    rotation_angle: this.overlay_parameter.rotation_angle,
                },
                enableUndo: true,
                loadMessage: `Redo Overlay resize for ${this.overlay.filename}`,
                rollbackMessage: `Undo Overlay resize for ${this.overlay.filename}`,
            });
        }
    }

    getNewPositions(index, quadPoints, mousePos) {
        const orderedPts = [...Array(4).keys()].map(i => quadPoints[(index + i) % 4]);
        const mouseLineDir = (new THREE.Vector3()).subVectors(orderedPts[1], orderedPts[0]);

        const mouseLine = new LineSegment2(mousePos, (new THREE.Vector2()).addVectors(mousePos, mouseLineDir));
        const adjacent1 = new LineSegment2(orderedPts[3], orderedPts[0]);
        const adjacent2 = new LineSegment2(orderedPts[2], orderedPts[1]);

        const intersection1 = mouseLine.intersectLineLine(adjacent1);
        const intersection2 = mouseLine.intersectLineLine(adjacent2);

        const minDistance = Math.min(intersection1.distanceToSquared(orderedPts[3]),
            intersection2.distanceToSquared(orderedPts[2]));

        if (minDistance >= 1 &&
            (new THREE.Vector2()).subVectors(intersection1,
                orderedPts[3]).dot(adjacent1.delta.clone().normalize()) > 0) {
            orderedPts[0] = intersection1;
            orderedPts[1] = intersection2;
        } else {
            orderedPts[0] = (new THREE.Vector2()).addVectors(orderedPts[3],
                adjacent1.delta.clone().normalize());
            orderedPts[1] = (new THREE.Vector2()).addVectors(orderedPts[2],
                adjacent2.delta.clone().normalize());
        }

        for (let i = 0; i < 4; i++) {
            quadPoints[(index + i) % 4] = orderedPts[i];
        }

        return quadPoints;
    }
}

export class DragActionImageOverlayVertexEdit {
    // Vertex refers to any corner of the overlay, so editing the vertex
    // means scaling the image.
    constructor(dRenderer, overlay, index, event) {
        this.dRenderer = dRenderer;
        this.overlay = overlay;
        this.overlay_parameter = overlay.overlay_parameter;
        this.index = index;
        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);
        this.downPoint = gp;
    }

    dragMouseMove(event) {
        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);

        let quadPoints = getFourPoints(this.overlay);
        const ratio = this.getRatio(quadPoints);

        quadPoints = this.getNewPositions(this.index, quadPoints, gp, ratio);

        // rotate the quadPoints back
        this.quadPoints = applyRotationToFourPoints(quadPoints, -this.overlay_parameter.rotation_angle);
        this.overlay.overlay_parameter = {
            quad_points: this.quadPoints,
            opacity: this.overlay_parameter.opacity,
            rotation_angle: this.overlay_parameter.rotation_angle,
        };

        this.dRenderer.renderOverlay({
            overlay: this.overlay,
            options: {
                limitOpacity: true,
                renderOnTop: true,
            },
        });

        this.downPoint = gp;
    }

    dragMouseUp() {
        if (this.quadPoints) {
            this.dRenderer.dispatcher.createSinglePropertyChange({
                resource: this.overlay,
                path: 'overlay_parameter',
                oldVal: this.overlay_parameter,
                newVal: {
                    quad_points: this.quadPoints,
                    opacity: this.overlay_parameter.opacity,
                    rotation_angle: this.overlay_parameter.rotation_angle,
                },
                enableUndo: true,
                loadMessage: `Redo Overlay scale for ${this.overlay.filename}`,
                rollbackMessage: `Undo Overlay scale for ${this.overlay.filename}`,
            });
        }
    }

    getScalingT(mousePos, origin, dir, ratio) {
        const t1 = (mousePos.x - origin.x) / dir.x;
        const t2 = (mousePos.y - origin.y) / dir.y;
        let t = 0;
        const minimumScaling = Math.sqrt(1 + (ratio * ratio));
        t = Math.max(Math.min(t1, t2), minimumScaling);

        const mouseFromPoint = (new THREE.Vector3()).subVectors(mousePos, origin);
        const degree = Math.acos(dir.dot(mouseFromPoint) / mouseFromPoint.length());

        if (degree < Math.PI / 6) {
            t = mousePos.distanceTo(origin);
        }
        return t;
    }

    getNewPositions(index, quadPoints, mousePos, ratio) {
        const xPrimeAxis = (new THREE.Vector3()).subVectors(quadPoints[1], quadPoints[0]);
        const yPrimeAxis = (new THREE.Vector3()).subVectors(quadPoints[3], quadPoints[0]);

        xPrimeAxis.normalize();
        yPrimeAxis.normalize();

        const xPosYPos = (new THREE.Vector3()).addVectors(xPrimeAxis, yPrimeAxis);
        xPosYPos.normalize();

        const fixedPointIndex = (index + 2) % 4;
        const dir = (new THREE.Vector3()).subVectors(quadPoints[index], quadPoints[fixedPointIndex]);
        dir.normalize();
        const t = this.getScalingT(mousePos, quadPoints[fixedPointIndex], dir, ratio);
        const theta = Math.atan(ratio);

        const length = t * Math.cos(theta);
        const width = t * Math.sin(theta);
        const xScaled = (new THREE.Vector3()).copy(xPrimeAxis).multiplyScalar(length);
        const yScaled = (new THREE.Vector3()).copy(yPrimeAxis).multiplyScalar(width);

        const scaledPoints = [
            (new THREE.Vector3(0, 0, 0)),
            xScaled,
            (new THREE.Vector3()).addVectors(xScaled, yScaled),
            yScaled,
        ];

        const difference = (new THREE.Vector3()).subVectors(quadPoints[fixedPointIndex], scaledPoints[fixedPointIndex]);
        for (const point of scaledPoints) {
            point.add(difference);
        }

        return scaledPoints;
    }

    getRatio(quadPoints) {
        const length = quadPoints[0].distanceTo(quadPoints[1]);
        const width = quadPoints[0].distanceTo(quadPoints[3]);
        return width / length;
    }
}

export class DragActionImageOverlayRotationEdit {
    constructor(dRenderer, overlay, event) {
        this.dRenderer = dRenderer;
        this.overlay = overlay;
        this.overlay_parameter = overlay.overlay_parameter;
        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);
        this.downPoint = gp;
    }

    dragMouseMove(event) {
        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);

        this.angle = this.getAngle(this.downPoint, gp, event);
        this.angle = this.angle + this.overlay_parameter.rotation_angle;
        this.quadPoints = getFourPoints(this.overlay, false);
        this.overlay.overlay_parameter = {
            quad_points: getFourPoints(this.overlay, false),
            opacity: this.overlay_parameter.opacity,
            rotation_angle: this.angle,
        };

        this.dRenderer.renderOverlay({
            overlay: this.overlay,
            options: {
                limitOpacity: true,
                renderOnTop: true,
            },
        });
    }

    dragMouseUp() {
        if (this.quadPoints) {
            this.dRenderer.dispatcher.createSinglePropertyChange({
                resource: this.overlay,
                path: 'overlay_parameter',
                oldVal: this.overlay_parameter,
                newVal: {
                    quad_points: this.quadPoints,
                    opacity: this.overlay_parameter.opacity,
                    rotation_angle: this.angle,
                },
                enableUndo: true,
                loadMessage: `Redo Overlay rotation for ${this.overlay.filename}`,
                rollbackMessage: `Undo Overlay rotation for ${this.overlay.filename}`,
            });
        }
    }

    getAngle(startMouse, currMouse, event) {
        const centerPoint = getCenterPoint(this.overlay_parameter.quad_points);
        const v1 = (new THREE.Vector3()).subVectors(startMouse, centerPoint).normalize();
        const v2 = (new THREE.Vector3()).subVectors(currMouse, centerPoint).normalize();
        const cross = (new THREE.Vector3()).crossVectors(v1, v2);

        // Clamp the dot product between -1 and 1; otherwise, values that are out of
        // range trigger NaN when calculating the acos (inverse cosine).
        const dotProduct = Math.max(-1, Math.min(v1.dot(v2), 1));
        let angle = Math.acos(dotProduct);

        if (cross.z < 0) {
            angle *= -1;
        }
        if (event.shiftKey) {
            angle = Math.floor((angle + (Math.PI / 8.0)) / (Math.PI / 4.0)) * (Math.PI / 4.0);
        }

        return angle;
    }
}
