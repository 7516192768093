/* tslint:disable:variable-name function-name */
import { Intent } from '@blueprintjs/core';
import { first } from 'lodash';
import Logger from 'js-logger';
import moment from 'moment';
import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

import { DeepPartial } from 'reports/types';
import { schema } from './schema';
import * as sub from './subscription';
import * as su from './subscription_user';
import * as team from './team';
import type { WireUnit } from './wire';

const logger = Logger.get('user');

export interface IDisplayMetric {
    selected_metric: string;
    display_metric_bar?: boolean;
}

interface IDesignRenderPreferences {
    modules: boolean;
    inverters: boolean;
    combiners: boolean;
    wiring: boolean;
    field_segments: boolean;
    keepouts: boolean;
    interconnect: boolean;
    overlays: boolean;
    resolution: IResolutionMetric;
    premades: boolean;
}

interface IDesignerPreferences {
    field_segment_labels: boolean;
    keepout_labels: boolean;
    label_scale_factor: number;
}

interface IWiringPreferences {
    wire_units: WireUnit;
    show_aluminum: boolean;
}

interface IRole {
    title: string;
    can_view_team_resources: boolean;
    can_manage_team_resources: boolean;
    can_manage_billing: boolean;
    can_view_settings: boolean;
    can_lock_design: boolean;
    can_view_financials: boolean;
    can_use_single_trackers: boolean;
    will_lose_financials_on_trial_to_basic_conversion: boolean;
}

export interface IUserPreferences {
    design_renders: IDesignRenderPreferences;
    units: IUnitPreferences;
    designer: IDesignerPreferences;
    wiring: IWiringPreferences;
    display_metric?: IDisplayMetric;
}

interface IUnitPreferences {
    distance: 'ft' | 'm';
}

interface IResolutionMetric {
    x: number;
    y: number;
}

export interface ILoginForm {
    email: string;
    password: string;
    remember_me: boolean;
}

export interface IPasswordForm {
    email: string;
    password: string;
    new_password: string;
    confirm_password: string;
}

interface IPhoneNumber {
    number: string;
    digits: string;
    dial_code: string;
    iso2_code: string;
    country_name: string;
}

enum UserStatuses {
    unactivated = 'Not Activated',
    trial = 'Trial',
    expired_trial = 'Expired Trial',
    active = 'Active',
    expired_subscription = 'Expired Subscription',
}

type UserStatus = keyof typeof UserStatuses;

enum TeamInviteStatuses {
    active = 'active',
    pending = 'pending',
    expired = 'expired',
    not_invited = 'not_invited',
}

type TeamInviteStatus = keyof typeof TeamInviteStatuses;

export const getUserStatusIntent = (status: UserStatus) => {
    switch (status) {
        case 'trial':
        case 'active':
            return Intent.SUCCESS;
        default:
            return Intent.DANGER;
    }
};

class User extends BaseClass {
    user_id: number;
    first_name: string;
    last_name: string;
    full_name: string;
    password: string;
    remember_me: boolean;
    email: string;
    phone: IPhoneNumber;
    company: string;

    // WARNING: Adding moments to `user` can cause worker serialization issues
    // If adding a moment, please fix the financial model code (look for `userWithTeam` declaration
    // in `src/client/reports/modules/financials/model/run.js`)
    current_period_end: moment.Moment;
    created?: moment.Moment;
    tou_accepted?: moment.Moment;
    tou_declined?: moment.Moment;
    tou_snooze_until?: moment.Moment;
    gdpr_authorization_ip?: string;

    activated: boolean;
    is_admin: boolean;
    is_current: boolean;
    default_profile_id?: number;
    flagged?: boolean;

    team_invite_status: TeamInviteStatus;

    _masquerading_admin?: boolean;
    _masquerading_admin_email?: string;

    team_id: number;
    team: team.Team;
    team_admin: boolean;

    status: UserStatus;
    subscription_external_id?: string;
    subscription?: sub.Subscription;
    has_inactive_v1_subscription: boolean;
    latest_subscription_external_id?: string;
    latest_subscription?: sub.Subscription;

    stripe_customer_id?: string;

    preferences: IUserPreferences;

    active_features: string[];

    beta_access_timestamp?: string;

    role: IRole;

    constructor(args) {
        super(User.deserializer(args));
    }

    static deserializer = BaseClass.getDeserializer({
        current_period_end: (x) => moment(x),
        created: (x) => moment(x),
        tou_accepted: (x) => moment(x),
        tou_declined: (x) => moment(x),
        tou_snooze_until: (x) => moment(x),
    });

    get teamInviteStatusText() {
        switch (this.team_invite_status) {
            case TeamInviteStatuses.active: {
                return 'Active';
            }
            case TeamInviteStatuses.expired: {
                return 'Expired';
            }
            case TeamInviteStatuses.pending: {
                return 'Pending';
            }
            case TeamInviteStatuses.not_invited: {
                return 'Not Invited';
            }
        }
        return null;
    }

    get statusText() {
        if (!this.status) {
            logger.warn('User has unknown status', this);
            return;
        }

        return UserStatuses[this.status];
    }

    fullName() {
        return `${this.first_name} ${this.last_name}`;
    }

    // Determines whether a user can legally access the system.
    isCurrent = () => this.is_current;
    isExpired = () => this.status === 'expired_trial' || this.status === 'expired_subscription';

    // TODO: fix me to work correctly when latest subscription is a pending (initialized) subscription. May want to
    // use latest_purchased_subscription()
    now = () => this.latest_subscription?.now() || moment();

    hasFeature(feature) {
        return this.active_features.includes(feature);
    }

    hasFinancialsAccess = () => {
        return this.role.can_view_financials || this.hasFeature('enable_financials_access');
    };

    hasSingleAxisTrackersAccess = () => {
        return this.role.can_use_single_trackers || this.hasFeature('enable_single_trackers');
    };
}

const schemaObj = schema.addObject(User, 'user', {
    relationships: {
        team: { schema: team.schemaObj, backref: 'users' },
        subscription: {
            schema: sub.schemaObj,
            idName: 'subscription_external_id',
        },
        latest_subscription: {
            schema: sub.schemaObj,
            idName: 'latest_subscription_external_id',
        },
    },
    defaultDeep: { team: '*' },
});
su.schemaObj.addRelationship('user', schemaObj);
sub.schemaObj.addRelationship('creator', schemaObj);

export const endpoint = ReduxEndpoint.fromSchema('/api/users/', schemaObj);

export interface IUserForm extends DeepPartial<User> {
    email: string;
    updated_email?: string;
    first_beta_access?: boolean;
}

const api = {
    index: endpoint.index(),
    get: endpoint.get<{ email: string }>('{email}'),
    create: endpoint.post(),
    save: endpoint.put<IUserForm | IPasswordForm, { action?: string }>('{email}'),
    resendAuthToken: endpoint.put<{ email: string }>('{email}/resend_token', ReduxEndpoint.PassThroughConfig()),
    extendTrial: endpoint.put<{ email: string }, { reason: string; days?: number }>('{email}/extend_trial'),
    trialExtensionCount: endpoint.get<{ email: string }, { count: number }>(
        '{email}/trial_extension_count',
        ReduxEndpoint.PassThroughConfig(),
    ),
    isEmailTaken: endpoint.get<{ email: string }, { taken: boolean }>(
        '{email}/is_taken',
        ReduxEndpoint.PassThroughConfig(),
    ),
    restartTrial: endpoint.post<{ email: string }>('{email}/restart_trial'),
};

const selectors = {
    byObject: schemaObj.selectByObject,
    byId: schemaObj.selectById,
    all: schemaObj.selectAll,
    byEmail: (state, email, deep?) =>
        first(
            schemaObj.selectAll(state, {
                deep,
                filter: (x) => x.email === email,
            }),
        ),
};

const actions = {
    dataLoaded: schemaObj.dataLoaded,
};

export { schemaObj, User, UserStatuses, TeamInviteStatuses, api, selectors, actions };

// // Angular API Definition
// User.configureRelationships({
//     default_logo: relationship(Logo, { id: 'default_logo_id' }),
//     team: relationship(Team, { backref: 'users' }),
//     current_period_end: (rawTime) => moment(rawTime),
//     subscription: (rawSub) => new Subscription(rawSub),
// });

// User.createEndpoint('/api/users/:email', {},
// { query: { method: 'GET', isArray: true, url: '/api/users/' },
// update: { method: 'PUT', isArray: false, params: { email: '@email' } },
// activate: { url: '/api/users/activate/:auth_token', method: 'PUT', isArray: false },
// resendAuthToken: { url: '/api/users/:email/resend_token', method: 'PUT', isArray: false },
// updatePreferences: { method: 'PUT', isArray: false, params: { email: '@email', action: 'update_preferences' } },
// updateRenderPreferences: { method: 'PUT', isArray: false, params: { email: '@email', action: 'update_renders' } },
// updateDesignerPreferences: { method: 'PUT', isArray: false, params: { email: '@email', action: 'update_designer' } },
// updatePassword: { method: 'PUT', isArray: false, params: { email: '@email', action: 'change_password' } },
// login: { url: '/api/users/login', method: 'POST', isArray: false, ignoreAuthModule: true },
// logout: { url: '/api/users/logout', method: 'POST', isArray: false },
// resetPassword: { url: '/api/users/reset', method: 'POST', isArray: false },
// extendTrial: { url: '/api/users/:email/extend_trial', method: 'PUT', isArray: false, params: { email: '@email' } },
// trialExtensions: { url: '/api/users/trial_extensions', method: 'GET', isArray: true },
// acquireSessionLock: { url: '/api/users/acquire_session_lock', method: 'PUT' },
// });
