import { pointInPolygon, sanitizePath } from 'helioscope/app/utilities/geometry';
import {
    $anchorScroll,
    $document,
    $filter,
    $state,
    Messager,
} from 'helioscope/app/utilities/ng';

import {
    disableFieldSegmentContextMenu,
    enableFieldSegmentContextMenu,
} from 'helioscope/app/utilities/maps/components';

import { isEventFromTextInput, KEY } from 'helioscope/app/utilities/helpers';

import * as SolarTime from 'helioscope/app/utilities/solar/solar_time';

import { EntityPremade } from 'helioscope/app/designer/premade/Premade';
import { premadeChangeConfig } from 'helioscope/app/designer/premade/actions';
import { MapConfig } from 'helioscope/app/designer/MapConfig';

import { fieldSegmentActions, getDefaultShadeTimes } from '../field_segment';
import { KeepoutActionsMixin, keepoutActions } from './actions';
import { Keepout } from './Keepout';
import { isMacLike } from 'helioscope/app/utilities/helpers';
import { $rootScope } from 'helioscope/app/utilities/ng';
import { trackKeyboardAction } from 'helioscope/app/utilities/analytics';

const { toggleModule } = fieldSegmentActions;

/**
 * for default values use the most common entries
 */
function findKeepoutDefaults(keepouts) {
    let defaultSetback = 0;
    let defaultHeight = 0;

    if (keepouts.length) {
        defaultSetback = _(keepouts)
            .groupBy('outerSetback')
            .map((val, key) => ({ outerSetback: key, count: val.length }))
            .sortBy('count')
            .last()
            .outerSetback;

        defaultHeight = _(keepouts)
            .groupBy('referenceHeight')
            .map((val, key) => ({ referenceHeight: key, count: val.length }))
            .sortBy('count')
            .last()
            .referenceHeight;
    }

    return { defaultSetback, defaultHeight };
}

class KeepoutPolygonCreator {
    constructor(dispatcher, design) {
        this.design = design;
        this.dispatcher = dispatcher;

        this.dispatcher.onRendererReady(() => {
            this.mapInput = this.dispatcher.renderer.mapInputFactory();
            this.clearAndStartNew();
        });
    }

    makeKeepout(path) {
        const keepouts = this.design.keepouts;

        // WARNING: not sure this is always going to get the most recent keepout since
        // relational parses it from an object
        const latest = _.last(keepouts);

        return new Keepout({
            design_id: this.design.design_id,

            // keepout the names in sync in case relational is not updated
            description: `Keepout ${keepouts.length + 1}`,
            outer_setback: latest ? latest.outer_setback : 0,
            reference_height: latest ? latest.referenceHeight : 0,
            geometry: { path: sanitizePath(path, 1e-2) }, // remove any points within 1cm when deduping
        });
    }

    inputKeepout() {
        this.mapInput.getPolygon(this.userLabelPrefs).then(
            (path) => {
                const keepout = this.makeKeepout(path);
                keepoutActions.createKeepout({ dispatcher: this.dispatcher, keepout })
                    .finally(
                        () => {
                            this.clearAndStartNew();
                            const mapControls = this.$scope.koCtrl.mapControls;

                            // a timeout is necessary to make sure the dom element is ready to scroll to
                            setTimeout(() =>
                                mapControls.highlight(
                                    keepout, true, { updateMap: false, scroll: true },
                                ));
                        });
            });
    }

    clearAndStartNew() {
        this.mapInput.clear();
        this.inputKeepout();
    }
}

export class CombinedKeepoutsCtrl extends KeepoutActionsMixin {
    constructor(design, dispatcher, $scope, $stateParams) {
        'ngInject';

        super(dispatcher);

        this.design = design;
        this.distanceFilter = $filter('hsDistance', 1);
        this.showShadePopover = false;
        this.entityAddType = 'polygon_prism';

        this.showInList = {
            polygon_prism: true,
            tree_sphere: true,
        };

        const { defaultSetback, defaultHeight } = findKeepoutDefaults(design.keepouts);
        this.defaultSetback = defaultSetback;
        this.defaultHeight = defaultHeight;

        this.isSODinProgress = this.dispatcher.sodHelper.isSODinProgress;

        this.dispatcher.onRendererReady(() => {
            this.createDocumentListeners(this.dispatcher, $scope);
        });

        this.startControl();

        $scope.$on('$destroy', () => this.stopControl());

        if ($stateParams.entity_premade_id == null && $stateParams.keepout_id == null) {
            this.updateMode(true);
        }
    }

    startControl() {
        disableFieldSegmentContextMenu();

        this.unsubscribers = [
            this.dispatcher.subscribe('entityPremadesChanged', this.handleChange),
            this.dispatcher.subscribe('entityKeepoutsChanged', this.handleChange),
            this.dispatcher.subscribe('entityHighlightChanged', this.handleHighlightChanged),
            this.dispatcher.subscribe('selectionModeExited', this.handleSelectionModeEnd.bind(this)),
            this.dispatcher.subscribe('SODInProgress', this.handleSODinProgressChange.bind(this)),
            this.dispatcher.subscribe('SODCompleted', this.handleSODinProgressChange.bind(this)),
        ];

        this.handleChange();
        this.dispatcher.setStateFlags({ keepoutsDraggable: true });
    }

    stopControl() {
        enableFieldSegmentContextMenu();

        for (const unsub of this.unsubscribers) {
            unsub();
        }

        this.unsubscribers = null;

        if (this.addMode) this.updateMode(false);

        if (this.dispatcher.selectedEntity instanceof EntityPremade ||
            this.dispatcher.selectedEntity instanceof Keepout) {
            this.dispatcher.selectEntity();
        }

        this.dispatcher.setStateFlags({ keepoutsDraggable: false });
    }

    isModeActive(mode) {
        if (this.dispatcher.selectMode) {
            return false;
        }

        if (mode === 'default') {
            return !this.addMode;
        }

        return this.entityAddType === mode && this.addMode;
    }

    updateMode(addMode = false, entityAddType = this.entityAddType) {
        this.entityAddType = entityAddType;
        this.addMode = addMode;

        if (addMode) {
            this.selectEntity();
        }

        this.dispatcher.onRendererReady(() => {
            this.updateTool(this.addMode);
        });
    }

    updateTool(addMode) {
        if (this.polygonCreator && this.dispatcher.selectedEntities.size <= 1) this.polygonCreator.clearAndStartNew();

        if (addMode) {
            if (this.entityAddType === 'polygon_prism') {
                this.polygonCreator = new KeepoutPolygonCreator(this.dispatcher, this.design);
            } else if (this.entityAddType === 'tree_sphere') {
                this.dispatcher.renderer.activateInteractTool({
                    tool: 'ToolAddPremade',
                });
            }
        } else {
            this.polygonCreator = null;
            this.dispatcher.renderer.activateInteractTool(null);
        }
    }
    handleSelectionModeEnd() {
        if (this.dispatcher.selectedEntities.size === 0) {
            this.updateMode(this.addMode, this.entityAddType)
        } else {
            this.addMode = false;
        }
    }
    handleHighlightChanged = (_dispatcher, pubsubOptions) => {
        if (pubsubOptions && pubsubOptions.sender === this) return;

        for (const ent of this.entities) {
            if (this.dispatcher.highlightedEntities.has(ent.entity)) {
                ent.expand = true;
            } else {
                ent.expand = false;
            }
        }

        for (const entity of this.dispatcher.highlightedEntities) {
            if (entity instanceof Keepout) {
                $anchorScroll(this.domId(entity));
                return;
            } else if (entity instanceof EntityPremade) {
                $anchorScroll(this.domId(entity));
                return;
            }
        }

        if (this.dispatcher.selectedEntity) {
            $anchorScroll(this.domId(this.dispatcher.selectedEntity));
        }
    }

    handleChange = (_dispatcher, options = {}) => {
        this.entities = this.design.keepouts.concat(this.design.entity_premades)
            .map(entity => ({
                entity,
                type: _.get(entity, 'geometry.premade_type', 'polygon_prism'),
                domId: this.domId(entity),
                expand: false,
            }));


        if (options.created && !options.isClone) {
            // give angular time to render the list before selecting
            setTimeout(() => this.selectEntity(options.created, true), 100);
        }
    };

    entityVisible = ({ type }) => (
        (type === 'polygon_prism' && this.showInList.polygon_prism === true) ||
        (type === 'tree_sphere' && this.showInList.tree_sphere === true)
    );

    selectEntity(entity, scrollTo = false) {
        this.dispatcher.selectEntity(entity);

        if (scrollTo) {
            $anchorScroll(this.domId(entity));
        }
    }

    highlightEntity(entity, highlight) {
        this.dispatcher.highlightEntity(entity, highlight, { sender: this });
    }

    entitySelected(entity) {
        return this.dispatcher.selectedEntity === entity;
    }

    entityHighlighted(entity) {
        return this.dispatcher.highlightedEntities.has(entity);
    }

    domId(entity) {
        if (entity instanceof Keepout) {
            return `keepout:${entity.keepout_id}`;
        }
        return `premade:${entity.entity_premade_id}`;
    }

    panToEntity(entity, select = false) {
        this.dispatcher.renderer.zoom(entity);

        if (select) {
            this.selectEntity(entity);
        }
    }

    deleteEntityFromList(entity) {
        const dispatcher = this.dispatcher;

        if (entity instanceof Keepout) {
            dispatcher.selectedEntity = null;
            return dispatcher.stateHandler.deleteObject(entity, this.changeConfig({ keepout: entity, dispatcher }));
        }

        dispatcher.selectedEntity = null;
        return dispatcher.stateHandler.deleteObject(entity, premadeChangeConfig(dispatcher, entity));
    }

    handleSODinProgressChange() {
        this.isSODinProgress = this.dispatcher.sodHelper.isSODinProgress;
    }

    isSODButtonDisabled(keepout) {
        return !keepout.isInsideFieldSegment(this.dispatcher) || this.isSODinProgress || keepout.hasTwoPoints();
    }

    getSODWarningMessage(keepout) {
        if (!keepout.isInsideFieldSegment(this.dispatcher)) {
            return 'Keepout must be within a field segment before using similar keepout detection';
        } else if (keepout.hasTwoPoints()) {
            return 'Keepout must have more than two points to use similar keepout detection';
        } else {
            return 'Only one keepout detection can run at a time';
        }
    }

    createDocumentListeners(dispatcher, $scope) {
        const keydown = `keydown.${this.constructor.name}`;

        $document.on(keydown, (evt) => {
            if (isEventFromTextInput(evt)) {
                return;
            }

            switch (evt.keyCode) {
                case KEY.ESC:
                    this.showShadePopover = false;
                    this.selectEntity(null);
                    this.updateTool(this.addMode);
                    break;
                case KEY.a:
                    this.updateMode(true, 'polygon_prism');
                    break;
                case KEY.t:
                    this.updateMode(true, 'tree_sphere');
                    break;
                case KEY.e:
                    this.updateMode(false);
                    break;
                case KEY.BACKSPACE:
                case KEY.DELETE:
                    if (this.dispatcher.selectedEntity) {
                        this.deleteEntityFromList(this.dispatcher.selectedEntity);
                    }
                    trackKeyboardAction('designer-shortcut-delete_keepout', this.design, $rootScope.user());
                    break;
                default:
                    break;
            }
        });

        const closeMapListener = dispatcher.renderer.overrideRightClick(({ location }) => {
            // remove modules on right click

            // disable module toggling if modules are invisible. User
            // can't see modules, so it's weird to be able to toggle
            // them and it also makes modules visible without updating
            // "Show Modules" checkbox
            //
            // note: this is slightly buggy in that it does not update if the user changes settings
            if (!dispatcher.showModules) {
                return false;
            }

            for (const fieldSegment of this.design.field_segments) {
                if (dispatcher.renderUpdater.dirtyEntities.has(fieldSegment)) {
                    continue;
                }

                if (pointInPolygon(location, fieldSegment.geometry.path)) {
                    const toggledModule = toggleModule({ fieldSegment, dispatcher, location });
                    if (toggledModule) {
                        return true;
                    }
                }
            }

            return false;
        });

        $scope.$on('$destroy', () => {
            closeMapListener();
            $document.off(keydown);
        });
    }
}


// TODO: MT: kill this
export class KeepoutsCtrl extends KeepoutActionsMixin {
    constructor(design, dispatcher, $scope) {
        'ngInject';

        super(dispatcher);

        this.design = design;
        this.distanceFilter = $filter('hsDistance', 1);

        this.dispatcher.onRendererReady(() => {
            this.createDocumentListeners(dispatcher, $scope);
            this.mapControls = new KeepoutMapControls(dispatcher, $scope);
        });

        const { defaultSetback, defaultHeight } = findKeepoutDefaults(design.keepouts);
        this.defaultSetback = defaultSetback;
        this.defaultHeight = defaultHeight;

        this.editShape = false;
        this.showShadePopover = false;

        disableFieldSegmentContextMenu();

        // make keepouts draggable without prior selection in keepouts view only
        MapConfig.keepout.hover.draggable = true;

        this.dispatcher.setStateFlags({ keepoutsDraggable: true });

        $scope.$on('$destroy', () => {
            // in other views we don't want keepouts to be drggable
            delete MapConfig.keepout.hover.draggable;

            enableFieldSegmentContextMenu();

            if (!dispatcher.renderer) {
                return;
            }

            this.updateKeepoutEditMode(false);

            this.dispatcher.setStateFlags({ keepoutsDraggable: false });
        });
    }

    toggleAddMode() {
        if ($state.is('designer.design.keepouts.new')) {
            $state.go('designer.design.keepouts');
        } else {
            $state.go('designer.design.keepouts.new');
        }
    }

    updateEditShape() {
        this.updateKeepoutEditMode(this.editShape);
    }

    updateKeepoutEditMode(editable) {
        const args = { renderOptions: { editable }, updatePath: false };
        for (const ko of this.design.keepouts) {
            this.dispatcher.renderer.renderKeepout(ko, args);
        }
    }

    createDocumentListeners(dispatcher, $scope) {
        // can't use dynamic name based on constructor because it will collide with other
        // constructor names after minification in production
        const keydown = 'keydown.KeepoutsCtrl';

        $document.on(keydown, (evt) => {
            if (isEventFromTextInput(evt)) {
                return;
            }

            switch (evt.keyCode) {
                case KEY.ESC:
                    this.showShadePopover = false;
                    break;
                case KEY.a:
                    this.toggleAddMode();
                    break;
                case KEY.e:
                    $state.go('designer.design.keepouts');
                    break;
                case KEY.s:
                    // $state.go('designer.design.keepouts.shading');
                    break;
                default:
                    break;
            }
        });

        const closeMapListener = dispatcher.renderer.overrideRightClick(({ location }) => {
            // remove modules on right click

            // disable module toggling if modules are invisible. User
            // can't see modules, so it's weird to be able to toggle
            // them and it also makes modules visible without updating
            // "Show Modules" checkbox
            //
            // note: this is slightly buggy in that it does not update if the user changes settings
            if (!dispatcher.showModules) {
                return false;
            }

            for (const fieldSegment of this.design.field_segments) {
                if (dispatcher.renderUpdater.dirtyEntities.has(fieldSegment)) {
                    continue;
                }

                if (pointInPolygon(location, fieldSegment.geometry.path)) {
                    const toggledModule = toggleModule({ fieldSegment, dispatcher, location });
                    if (toggledModule) {
                        return true;
                    }
                }
            }

            return false;
        });

        $scope.$on('$destroy', () => {
            closeMapListener();
            $document.off(keydown);
        });
    }
}


// TODO: MT: kill this
export class NewKeepoutsCtrl {
    constructor($scope, design, user, dispatcher) {
        'ngInject';

        this.design = design;
        this.dispatcher = dispatcher;
        this.$scope = $scope;

        this.userLabelPrefs = { labelScaleFactor: user.preferences.designer.label_scale_factor };

        $scope.$on('$destroy', () => {
            // when we're in SLD view, onRenderReady() doesn't fire
            if (this.mapInput) {
                this.mapInput.cancel();
            }
            $document.off('keydown.NewKeepoutCtrl');
        });

        $document.on('keydown.NewKeepoutCtrl', (evt) => {
            if (evt.keyCode === KEY.ESC) {
                this.clearAndStartNew();
            }
        });

        this.dispatcher.onRendererReady(() => {
            this.mapInput = this.dispatcher.renderer.mapInputFactory();
            this.clearAndStartNew();
        });
    }

    makeKeepout(path) {
        const keepouts = this.design.keepouts;

        // WARNING: not sure this is always going to get the most recent keepout since
        // relational parses it from an object
        const latest = _.last(keepouts);

        return new Keepout({
            design_id: this.design.design_id,

            // keepout the names in sync in case relational is not updated
            description: `Keepout ${keepouts.length + 1}`,
            outer_setback: latest ? latest.outer_setback : 0,
            reference_height: latest ? latest.referenceHeight : 0,
            geometry: { path: sanitizePath(path, 1e-2) }, // remove any points within 1cm when deduping
        });
    }

    inputKeepout() {
        this.mapInput.getPolygon(this.userLabelPrefs).then(
            (path) => {
                const keepout = this.makeKeepout(path);
                keepoutActions.createKeepout({ dispatcher: this.dispatcher, keepout })
                    .finally(
                        () => {
                            this.clearAndStartNew();
                            const mapControls = this.$scope.koCtrl.mapControls;

                            // a timeout is necessary to make sure the dom element is ready to scroll to
                            setTimeout(() =>
                                mapControls.highlight(
                                    keepout, true, { updateMap: false, scroll: true }));
                        });
            });
    }

    clearAndStartNew() {
        this.mapInput.clear();
        this.inputKeepout();
    }
}

// TODO: MT: kill this
export class EditKeepoutsCtrl {
    constructor(design) {
        'ngInject';

        this.design = design;
    }
}

export class KeepoutMenuCtrl extends KeepoutActionsMixin {
    constructor(keepout, dispatcher) {
        'ngInject';

        super(dispatcher, keepout);
        this.internalClipboard = dispatcher.internalClipboard;
        this.isSODinProgress = dispatcher.sodHelper.isSODinProgress;
    }

    hasComponentInClipboard() {
        return this.internalClipboard.isNotEmpty();
    }

    copyKeepout() {
        this.dispatcher.copyEntityFromMenu(this.keepout);
    }

    paste() {
        this.dispatcher.activatePasteMode();
    }

    isOnMac() {
        return isMacLike();
    }

    showContextMenuSODButton() {
        return !this.isSODinProgress && this.keepout.isInsideFieldSegment(this.dispatcher) && this.supportsSOD() && this.dispatcher.isSODEnabled() && !this.keepout.hasTwoPoints();
    }

}


export const KeepoutContextMenu = {
    templateUrl: require('helioscope/app/designer/keepout/partials/context.html'),
    controller: KeepoutMenuCtrl,
    controllerAs: 'koMenu',
};


export class KeepoutShadeCtrl {
    constructor($scope) {
        'ngInject';

        this.$scope = $scope;
    }

    open($event) {
        $event.preventDefault();
        $event.stopPropagation();

        this.opened = !this.opened;
    }


    init(dispatcher) {
        this.dispatcher = dispatcher;
        this.design = dispatcher.design;


        const { startTime, endTime } = getDefaultShadeTimes(this.dispatcher.design);

        this.pickerDate = startTime;
        this.startTime = startTime;
        this.endTime = endTime;

        this.storeSelectedTimes(startTime, endTime);

        const $scope = this.$scope;

        $scope.$watchGroup(['shadeCtrl.pickerDate', 'shadeCtrl.startTime', 'shadeCtrl.endTime'], () => {
            // invalid value in text entry of any of those boxes results in null value
            if (!this.pickerDate || !this.startTime || !this.endTime) {
                return;
            }
            const start = SolarTime.combineDateTime(this.pickerDate, this.startTime);
            const end = SolarTime.combineDateTime(this.pickerDate, this.endTime);
            this.storeSelectedTimes(start, end);
        });
    }

    storeSelectedTimes(startTime, endTime) {
        this.times = {
            startTime: SolarTime.utcDate(startTime).toISOString(),
            endTime: SolarTime.utcDate(endTime).toISOString(),
        };
    }

    /**
     * TODO: this should use the undo stack
     */
    save() {
        this.design.shade_keepouts_start = this.times.startTime;
        this.design.shade_keepouts_end = this.times.endTime;

        this.dispatcher.stateHandler.updateQueue.schedule(this.design);
        this.dispatcher.designManager.updateDesignShading();

        this.$scope.koCtrl.showShadePopover = false;
    }

    cancel() {
        this.$scope.koCtrl.showShadePopover = false;
    }
}

class KeepoutMapControls {
    constructor(dispatcher, $scope) {
        this.dispatcher = dispatcher;
        this.$scope = $scope;

        this.createHighlightListeners();
    }

    createHighlightListeners() {
        const dispatcher = this.dispatcher;

        const mouseoverListener = dispatcher.subscribe('Keepout:mouseover', (_dispatcher, { keepout }) => {
            this.highlight(keepout, true, { updateMap: false, scroll: true });
        });

        const mouseoutListener = dispatcher.subscribe('Keepout:mouseout', (_dispatcher, { keepout }) => {
            this.highlight(keepout, false, { updateMap: false, scroll: true });
        });

        this.$scope.$on('$destroy', () => {
            mouseoverListener();
            mouseoutListener();
        });
    }

    highlight(keepout, highlight, { updateMap = true, scroll = false } = {}) {
        delete this.mapHighlighted; // if the user mouses over a row, don't show the old highlighting

        if (highlight === true) {
            this.mapHighlighted = keepout;
        }

        if (updateMap === true) {
            this.dispatcher.renderer.highlightKeepout(keepout, highlight);
        }

        if (scroll === true) {
            $anchorScroll(this.domId(keepout));
        }


        if (!this.$scope.$$phase) {
            this.$scope.$apply();
        }
    }

    domId(keepout) {
        return `keepout:${keepout.keepout_id}`;
    }
}
