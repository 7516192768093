import Logger from 'js-logger';
import * as GLH from '../GLHelpers';
import { PrimitiveMeshFill } from '../Primitives';
import { WidgetImageOverlayCollection } from './WidgetImageOverlay';
import { getFourPoints, MAX_OVERLAY_ORDER } from './OverlayHelpers';

const logger = Logger.get('RenderableImageOverlay');

export class RenderableImageOverlay {
    constructor(renderer, overlay) {
        this.renderer = renderer;
        this.overlay = overlay;
        this.resourceKey = `_RenderableImageOverlay_${overlay.overlay_id}`;
        this.renderPromise = this.loadRenderableTexture();
    }

    ready() {
        return this.renderPromise;
    }

    loadRenderableTexture() {
        return this.renderer.loadTexture(null, this.overlay.file.get_url)
            .then((texture) => {
                this.renderer.graphicResourceCache[this.resourceKey] = texture;
                this.texture = texture;
                this.texture.image = GLH.resizeImageIfNeeded(this.texture.image);
            })
            .catch((err) => {
                logger.warn(err);
            });
    }

    clearRenderable() {
        this.clearWidgets();

        if (this.primitive) {
            this.primitive.clearInstances();
            this.primitive = null;
        }

        this.renderer.dirtyFrame();
    }

    renderRenderable(renderOptions = {}) {
        this.ready()
            .then(() => { this.internalRenderRenderable(renderOptions); })
            .catch((err) => {
                logger.warn(err);
            });
    }

    internalRenderRenderable(renderOptions) {
        this.clearRenderable();

        if (!this.overlay.visible || !this.overlay.overlay_parameter) return;

        const texture = this.renderer.graphicResourceCache[this.resourceKey];
        if (!texture) return;

        let opacity = this.overlay.overlay_parameter.opacity;
        if (renderOptions.limitOpacity) {
            // Limit the opacity to no more than 50%
            // This helps the user to see both the image overlay and what is underneath it
            opacity = Math.min(opacity, 0.5);
        }

        const renderOrder = renderOptions.renderOnTop ? MAX_OVERLAY_ORDER : this.overlay.order;

        this.renderer.primOptions = {
            geometry: GLH.makeQuadTexturedGeometryFromPoints(getFourPoints(this.overlay)),
            material: this.renderer.inlineShaderMaterial('vertexShaderTexture', 'fragmentShaderTexture'),
            scene: this.renderer.overlayLayer,
            fillColor: '#ffffff',
            texture,
            opacity,
            renderOrder,
            selectionData: null,
        };

        this.primitive = this.renderer.renderPrimitive(PrimitiveMeshFill, this.renderer.primOptions);

        if (renderOptions.renderEditWidgets) {
            this.createWidgets();
        }
    }

    createWidgets() {
        if (this.widgetCollection) return;
        const dragHandles = true;
        const edgeHandles = true;
        const vertexHandles = true;
        const rotationHandle = true;
        const options = { dragHandles, edgeHandles, vertexHandles, rotationHandle };

        this.widgetCollection = new WidgetImageOverlayCollection(this.renderer, this.overlay);
        this.widgetCollection.createWidget(options);
    }

    clearWidgets() {
        if (this.widgetCollection) {
            this.widgetCollection.clearWidgets();
            this.widgetCollection = null;
        }
    }
}
