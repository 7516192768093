import { EditSubscriptionCtrl } from './controllers';

export function subscription() {
    'ngInject';

    return {
        restrict: 'E',
        templateUrl: require('helioscope/app/users/subscriptions/partials/subscription.html'),
        scope: {
            subscription: '=',
            user: '=',
        },
        controller: EditSubscriptionCtrl,
        controllerAs: 'editSubscriptionCtrl',
    };
}
