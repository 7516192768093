import * as analytics from 'helioscope/app/utilities/analytics';
import { asyncLoadScript } from 'helioscope/app/utilities/helpers';
import { User } from 'helioscope/app/users/resources';
import * as subscriptions from './subscriptions';
import { TEMPLATES as PROFILE_TEMPLATES } from './profiles';

angular.module('helioscope.users', ['helioscope.users.controllers',
    'helioscope.users.config',
    'helioscope.users.resources',
    'helioscope.users.services',
    'helioscope.users.directives',

    // feature modules
    'helioscope.users.auth',
    'helioscope.users.logos',
    'helioscope.users.teams',
    'helioscope.users.profiles',
    'helioscope.users.external_credentials']);

angular.module('helioscope.users.resources', [])
    .factory('User', () => User)
    .factory('Subscription', () => subscriptions.Subscription);


const mod = angular.module('helioscope.users.config', ['ui.router', 'helioscope.users.auth']);

mod.config(['$stateProvider', 'auth.accessLevels', ($stateProvider, accessLevels) => {
    $stateProvider
        .state('account', {
            url: '/account',
            template: '<div class="col-xs-12" ui-view></div>',
            controller: 'AccountCtrl',
            resolve: {
                loggedInUser(Authenticator) {
                    return Authenticator.authorize(accessLevels['public']);
                },
            },
        })
        .state('account.detail', {
            url: '/:email',
            abstract: true,
            resolve: {
                selectedUser($stateParams) {
                    return User.get({ email: $stateParams.email || 'me' }).$promise;
                },
            },
            views: {
                'sidebar@account.detail': {
                    templateUrl: require('helioscope/app/users/partials/account.detail.sidebar.html'),
                    controller(selectedUser) { this.selectedUser = selectedUser; },
                    controllerAs: 'sidebarCtrl',
                },
                '': {
                    templateUrl: require('helioscope/app/users/partials/account.detail.html'),
                    controller: 'AccountDetailCtrl',
                },
            },
        })
        .state('account.detail.user', {
            url: '',
            controller: 'AccountUserCtrl',
            templateUrl: require('helioscope/app/users/partials/account.detail.user.html'),
        })
        .state('account.detail.password', {
            url: '/password',
            controller: 'PasswordCtrl',
            templateUrl: require('helioscope/app/users/partials/account.detail.password.html'),
        })
        .state('account.detail.preferences', {
            url: '/preferences',
            controller: 'PreferencesCtrl',
            templateUrl: require('helioscope/app/users/partials/account.detail.preferences.html'),
            resolve: {
                wires: WireLibrary => WireLibrary(),
                modules: Module => Module.project_modules().$promise,
            },
        })
        .state('account.detail.billing', {
            url: '/billing',
            views: {
                '': {
                    templateUrl: require('helioscope/app/users/subscriptions/partials/billing.html'),
                    controller: subscriptions.AccountBillingCtrl,
                    controllerAs: 'accountBillingCtrl',
                    resolve: {
                        allV1Subscriptions: ($stateParams) => (
                            subscriptions.Subscription.query({ email: $stateParams.email }).$promise
                        ),
                    },
                },
            },
        })
        .state('account.detail.profiles', {
            url: '/profiles',
            abstract: true,
            resolve: {
                profiles(Profile, $stateParams) { return Profile.query($stateParams).$promise; },
            },
            views: {
                'sidebar@account.detail': {
                    template: '',
                },
                '': {
                    controller: 'ProfilesCtrl as profilesCtrl',
                    templateUrl: require('helioscope/app/users/partials/profiles/index.html'),
                },
            },
        })
        .state('account.detail.profiles.master', {
            url: '',
            templateUrl: require('helioscope/app/users/partials/profiles/list.html'),
        })
        .state('account.detail.profiles.list', {
            url: '/:ui_profile_type',
            templateUrl: require('helioscope/app/users/partials/profiles/list.html'),
            controller: 'ProfileListCtrl as profileListCtrl',
            resolve: {
                profileType($stateParams, profileFilter) {
                    return profileFilter($stateParams.ui_profile_type);
                },
            },
        })
        .state('account.detail.profiles.list.new', {
            url: '/new?{copy_profile_id:int}',
            // note this state is implementing the same controller interface as the detail profile control
            controller: 'ProfileDetailCtrl as profileDetailCtrl',
            templateUrl: require('helioscope/app/users/partials/profiles/detail.html'),
            resolve: {
                defaults(Profile) { return Profile.defaults().$promise; },
                profile(Profile, $stateParams, defaults, profileType) {
                    // if we're going to share the same ProfileDetailCtrl
                    // we need to create a new profile here
                    if ($stateParams.copy_profile_id) {
                        const baseProfile = Profile.cached($stateParams.copy_profile_id) || {};

                        return new Profile(angular.extend(
                            {},
                            { data: angular.copy(defaults[profileType]) },
                            baseProfile,
                            {
                                name: `${baseProfile.name} (copy)`,
                                creator_id: undefined,
                                profile_id: undefined,
                                public: false,
                            },
                        ));
                    } else {
                        analytics.track('profile.new', { profile_type: profileType });
                        return new Profile({
                            type: profileType,
                            name: `${_.capitalize($stateParams.ui_profile_type)} Profile`,
                            data: angular.copy(defaults[profileType]),
                        });
                    }
                },
                formTemplateUrl(profileType) {
                    return PROFILE_TEMPLATES[profileType];
                },
            },
        })
        .state('account.detail.profiles.list.detail', {
            url: '/{profile_id:int}',
            controller: 'ProfileDetailCtrl as profileDetailCtrl',
            templateUrl: require('helioscope/app/users/partials/profiles/detail.html'),
            resolve: {
                defaults(Profile) { return Profile.defaults().$promise; },
                profile(profiles, $q, $stateParams, profileType) {
                    const profile = _.find(profiles, { profile_id: $stateParams.profile_id,
                        type: profileType });
                    return profile || $q.reject({ status: 404 });
                },
                components: (profile) => profile.data.loadDependencies(),
                formTemplateUrl(profileType) {
                    return PROFILE_TEMPLATES[profileType];
                },
            },
        })
        .state('account.detail.profiles.list.detail.edit', {
            url: '/edit',
            controller: 'ProfileEditCtrl as profileEditCtrl',
        })
        .state('account.detail.team', {
            url: '/team',
            resolve: {
                team(selectedUser, Team) {
                    return Team.get({ team_id: selectedUser.team_id }).$promise;
                },
            },
            controller: 'TeamCtrl as teamCtrl',
            templateUrl: require('helioscope/app/users/partials/teams/index.html'),
        })
        .state('payments', {
            url: '/payments/:external_id',
            template: '<div ui-view></div>',
            controller: subscriptions.PaymentCtrl,
            resolve: {
                subscription: ($stateParams) => subscriptions.Subscription.get($stateParams).$promise,
            },
        })
        .state('payments.invoice', {
            url: '/:invoice_external_id',
            templateUrl: require('helioscope/app/users/subscriptions/partials/payment.html'),
            controller: subscriptions.PaymentInvoiceCtrl,
            resolve: {
                invoice: ['subscription', '$stateParams', (subscription, $stateParams) => {
                    const invoice = _.find(
                        subscription.invoices, (i) => i.external_id === $stateParams.invoice_external_id,
                    );

                    if (!invoice) {
                        throw new Error("Can't find invoice");
                    }

                    return invoice;
                }],
            },
        })

        .state('account.detail.external_credentials', {
            url: '/external_credentials',
            templateUrl: require('helioscope/app/users/partials/external_credentials/settings.html'),
            controller: 'ExternalCredentialsCtrl as externalCredentialsCtrl',
            resolve: {
                allExternalCredentials: (ExternalCredentials) => ExternalCredentials.query().$promise,
            },
        })
        // modifications to home State
        .modalState('home.expired', {
            url: 'expired',
            modalOptionsFactory() {
                return {
                    windowClass: 'expired-modal',
                    size: 'lg',
                    templateUrl: require('helioscope/app/users/partials/expired-modal.html'),
                    controller: 'ExpiredModalCtrl',
                    resolve: {
                        // Added this to indicate out which route the expired modal was triggered from
                        showProjectLimitHeader: () => false,
                        trialExtensionCount: ['Authenticator', (Authenticator) => Authenticator.user().$trialExtensionCount()],
                    },
                };
            },
        })
        .modalState('home.notActivated', {
            url: 'not-activated?email',
            modalOptionsFactory() {
                return {
                    windowClass: 'not-activated-modal',
                    templateUrl: require('helioscope/app/users/partials/not-activated-modal.html'),
                    controller: 'NotActivatedModalCtrl',
                };
            },
        })
        .modalState('home.activated', {
            url: 'activate/:auth_token',
            modalOptionsFactory: ['$stateParams', ($stateParams) => ({
                windowClass: 'activated-modal',
                template: '<div></div>',
                resolve: {
                    user: ['$q', 'Authenticator', 'Messager', '$state', '$cookies',
                        ($q, Authenticator, Messager, $state, $cookies) => {
                            const notify = Messager.load('Activating your account...');

                            return User.activate($stateParams, {}).$promise.then(
                                (user) => {
                                    notify.success('Activated your account!');
                                    $cookies.put('firstTimeDesigner', 'true');
                                    Authenticator.loginUser(user);
                                    $state.go('home.welcome');
                                },
                                () => {
                                    notify.error('Could not activate your account with that token');
                                    $q.reject({ type: 'activationError' });
                                },
                            );
                        }],
                },
            })],
        })
        .modalState('home.welcome', {
            url: 'welcome',
            modalOptionsFactory() {
                return {
                    windowClass: 'activated-modal',
                    templateUrl: require('helioscope/app/users/partials/activated-modal.html'),
                    controller: 'WelcomeCtrl as welcomeCtrl',
                    resolve: {
                        user: [
                            'Authenticator', Authenticator => Authenticator.authorize(accessLevels.user),
                        ],
                        defaultProfiles: [
                            'Profile', Profile => Profile.query({ public: true, type: 'project' }).$promise,
                        ],
                        projects: [
                            'Project', Project => Project.query({ limit: 10000, include_archived: true }).$promise,
                        ],
                    },
                };
            },
        })
        .modalState('home.updatedTerms', {
            url: 'updated-terms/:toState',
            modalOptionsFactory() {
                return {
                    windowClass: 'updated-terms-modal',
                    templateUrl: require('helioscope/app/users/partials/updated-terms-modal.html'),
                    controller: 'UpdatedTermsModalCtrl',

                    // Disable modal close
                    keyboard: false,
                    backdrop: 'static',
                };
            },
        })
        .modalState('home.updatedTOUSnoozeable', {
            url: 'updated-tou-snoozeable/:toState',
            modalOptionsFactory() {
                return {
                    windowClass: 'updated-tou-snoozeable-modal',
                    templateUrl: require('helioscope/app/users/partials/updated-tou-snoozeable-modal.html'),
                    controller: 'UpdatedTOUSnoozeableCtrl',

                    // Disable modal close
                    keyboard: false,
                    backdrop: 'static',
                };
            },
        })
        .modalState('home.updatedTOUFinal', {
            url: 'updated-tou-final/:toState',
            modalOptionsFactory() {
                return {
                    windowClass: 'updated-tou-final-modal',
                    templateUrl: require('helioscope/app/users/partials/updated-tou-final-modal.html'),
                    controller: 'UpdatedTOUFinalCtrl',

                    // Disable modal close
                    keyboard: false,
                    backdrop: 'static',
                };
            },
        });
}]);
