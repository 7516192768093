import { relationship, deserializeObject } from 'helioscope/app/relational';
import { Vector, Bounds, rotatePath } from 'helioscope/app/utilities/geometry';
import { PhysicalSurface } from '../field_segment/PhysicalSurface';
import { FieldSegment } from '../field_segment';

export class Keepout extends PhysicalSurface {
    static relationName = 'Keepout';

    referencePoint() {
        if (!this.geometry.height_reference) {
            // for now, create this here.  Using the bounds midpoint is consistent with what
            // people had been using on New Designer before the point formally existed, so this
            // prepopulation currently handles 'unmigrated' new designs from the new designer.
            //
            // The server side population makes sure this point is backwards compatible with the
            // old way of finding the reference midpoint: (avgX, avgY)  (instead of bbox.midpoint)
            this.geometry.height_reference = Bounds.pathMidPoint(this.geometry.path);
        }

        return this.geometry.height_reference.getCopy();
    }

    castsShadows() {
        return this.referenceHeight > 0;
    }

    receivesShadows() {
        return true;
    }

    surfaceAzimuth() {
        // if a keepout has a parent and no height, make it flush against the parent
        // surface by having it inherit the tilt and orientation

        const parent = this.parentSurface();
        if (parent && !this.referenceHeight) {
            return parent.surfaceAzimuth();
        }

        return 180;
    }

    surfaceTilt() {
        // if a keepout has a parent and no height, make it flush against the parent
        // surface by having it inherit the tilt and orientation

        const parent = this.parentSurface();
        if (parent && !this.referenceHeight) {
            return parent.surfaceTilt();
        }

        return 0;
    }

    move(shift, { shiftPath = false } = {}) {
        if (shiftPath && this.geometry.path) {
            // optionally shift the path, because when an event comes from the map, the path
            // has already been moved
            this.geometry.path = this.geometry.path.map(loc => shift.add(loc));
            this.geometry.height_reference = Bounds.pathMidPoint(this.geometry.path);
        }
    }

    rotate(angle) {
        if (this.geometry.path) {
            this.geometry.path = rotatePath(this.geometry.path, this.centroid(), angle);
        }
    }

    toString() {
        return this.description;
    }

    isInsideFieldSegment(dispatcher) {
        const node = dispatcher.design.$$designScene.getNode(this);

        for (const stackableNode of node.containedBy2d) {
            if (stackableNode.surface instanceof FieldSegment) {
                return true;
            }
        }
        return false;
    }

    hasTwoPoints() {
        return this.geometry.path.length <= 2;
    }
}

Keepout.configureRelationships({
    design: relationship('Design', { backref: 'keepouts' }),
    'geometry.path': deserializeObject(Array(Vector)),
    'geometry.path_3d': deserializeObject(Array(Vector)),
    'geometry.base_3d': deserializeObject(Array(Vector)),
    'geometry.height_reference': deserializeObject(Vector),
});

Keepout.createEndpoint('/api/keepouts/:keepout_id',
    { keepout_id: '@keepout_id' },
    { update: { method: 'PUT', isArray: false } });
