import _ from 'lodash';
import * as THREE from 'three';

import {
    correctOrientation,
    Plane,
    Vector,
} from 'helioscope/app/utilities/geometry';
import { arrayItemWrap } from 'helioscope/app/utilities/helpers';
import { PhysicalSurface } from 'helioscope/app/designer/field_segment/PhysicalSurface';
import { EntityPremade } from 'helioscope/app/designer/premade/Premade';

import { actionPremadeCreate, actionPremadeMove } from 'helioscope/app/designer/premade';
import { RendererOptions } from 'helioscope/app/apollo/RendererOptions';
import { makeWireGeometry, makePhysicalSurfaceSegmentPoints } from './GLHelpers';
import { PrimitiveMeshStroke } from './Primitives';
import { WidgetSurfaceCollection } from './WidgetSurface';
import {
    autoPanCamera,
    bestParentSurface,
    bestParentSurfaceRay,
    computeAnglePointSnap,
    containerPoint,
    interactGroundPoint,
    interactRay,
    panThreshold,
    publishUpdatePath,
    rayGroundPlaneIntersect,
    raySurfaceIntersect,
    snapDistance,
    snapVector,
    toGeometryUtilVectorPath2D,
    toGeometryUtilVectorPath3D,
    SurfaceCursorHelper,
    zoomView,
} from './InteractHelpers';
import { DragActionCameraPan, DragActionCameraRotate } from './DragCameraActions';
import { PasteAction } from '../designer/actions';
import { CursorConfig } from '../designer/CursorConfig';
import { Keepout } from '../designer/keepout';
import { FieldSegment } from '../designer/field_segment';
import { bulkActions } from '../designer/BulkActionsMixin';


function moveMultipleEntities(renderer, entities, delta) {
    const newState = new Map();
    const previousState = new Map();

    for (const entity of entities) {
        const entityClone = _.cloneDeep(entity);
        if (entity instanceof FieldSegment) {
            previousState.set(entity, { geometry: { ...entityClone.geometry, path: entityClone.geometry.path }, racking: { ...entityClone.racking } });

            entityClone.move(delta, { shiftPath: true });
            const { geometry } = entityClone;

            newState.set(entity, { geometry: { ...geometry, path: geometry.path }, racking: { ...entityClone.racking } });
        } else if (entity instanceof Keepout) {
            previousState.set(entity, { geometry: { ...entityClone.geometry, path: entityClone.geometry.path } });

            entityClone.move(delta, { shiftPath: true });
            const { geometry } = entityClone;

            newState.set(entity, { geometry: { ...geometry, path: geometry.path } });
        } else if (entity instanceof EntityPremade) {
            const { geometry } = entity;
            const { position } = geometry.parameters;
            const oldPosition = new Vector(position.x, position.y);
            const newPosition = new Vector(position.x + delta.x, position.y + delta.y);

            previousState.set(entity, { geometry: { ...geometry, parameters: { ...geometry.parameters, position: oldPosition } } });
            newState.set(entity, { geometry: { ...geometry, parameters: { ...geometry.parameters, position: newPosition } } });
        }
    }

    bulkActions.update(renderer.dispatcher, Array.from(entities), null, { previousState, newState });
}

function moveEntityPosition(renderer, entity, delta) {
    const { path } = entity.geometry;
    const oldPath = _.map(path, i => i);
    const newPath = _.map(path, i => i.add(delta));
    const { dispatcher } = renderer;

    if (entity instanceof FieldSegment) {
        dispatcher.publish('FieldSegment:dragend',
            {
                fieldSegment: entity,
                delta: Vector.fromObject(delta),
                oldPath,
                newPath,
            });
        entity.geometry.path = newPath;
        renderer.renderFieldSegment(entity);

    } else if (entity instanceof Keepout) {
        dispatcher.publish('Keepout:dragend',
            {
                keepout: entity,
                delta: Vector.fromObject(delta),
                oldPath,
                newPath,
            });
        entity.geometry.path = newPath;
        renderer.renderKeepout(entity);

    } else if (entity instanceof EntityPremade) {
        const { position } = entity.geometry.parameters;
        actionPremadeMove({
            dispatcher,
            premade: entity,
            position: new Vector(position.x + delta.x, position.y + delta.y),
        });

        const renderable = renderer.objectRenderMap.get(entity);
        if (renderable) {
            renderable.updateRenderable(_.assign({}, renderable.options, { dragging: false }));
        }
    }
}

export class DragActionMovePhysicalSurface {
    constructor(dRenderer, event, moveObject) {
        this.dRenderer = dRenderer;
        this.moveObject = moveObject;

        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);

        this.downGroundPoint = gp;
        const { interactData } = this.moveObject.userData;

        const oldPath = _.map(interactData.object.geometry.path, i => i);
        if (interactData.type === 'Keepout') {
            this.dRenderer.dispatcher.publish('Keepout:dragstart',
                {
                    keepout: interactData.object,
                    altClick: event.altKey, oldPath,
                });
        }
    }

    handleCursorStyle(event) {
        if (event.altKey) {
            this.dRenderer.setCursorStyle(CursorConfig.DUPLICATE);
        } else {
            this.dRenderer.setCursorStyle(CursorConfig.MOVE);
        }
    }

    handleDummyMove() {
        if (this.dummyMove) {
            this.dummyMove.cancel();
            this.dummyMove = null;
        }
    }

    getPtAndDelta(event) {
        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);
        const delta = (new THREE.Vector3()).subVectors(gp, this.downGroundPoint);
        return { pt, delta };
    }

    renderAtNewPosition(entity, delta, pt, event) {
        const renderable = this.dRenderer.objectRenderMap.get(entity);
        if (renderable) {
            renderable.offsetRenderablePosition(delta);
            this.dRenderer.dirtyFrame();

            this.dummyMove = autoPanCamera(this.dRenderer, pt,
                () => {
                    this.dragMouseMove(event);
                });
        }
    }

    dragMouseMove(event) {
        this.handleCursorStyle(event);
        if (!this.downGroundPoint) return;

        this.handleDummyMove();

        const { pt, delta } = this.getPtAndDelta(event);

        const selectedEntities = this.dRenderer.dispatcher.selectedEntities;
        const dragEntity = this.moveObject.userData.interactData.object;
        if (selectedEntities.has(dragEntity)) {
            for (const entity of selectedEntities) {
                this.renderAtNewPosition(entity, delta, pt, event);
            }
        } else {
            this.renderAtNewPosition(dragEntity, delta, pt, event);
        }
    }

    dragMouseUp(event) {
        this.dRenderer.setCursorStyle(CursorConfig.DEFAULT);

        if (!this.downGroundPoint) return;

        const { delta } = this.getPtAndDelta(event);

        const selectedEntities = this.dRenderer.dispatcher.selectedEntities;
        const dragEntity = this.moveObject.userData.interactData.object;
        if (selectedEntities.has(dragEntity) && selectedEntities.size > 1) {
            moveMultipleEntities(this.dRenderer, selectedEntities, new Vector(delta.x, delta.y, delta.z));
        } else {
            moveEntityPosition(this.dRenderer, dragEntity, delta);
        }
    }

    dragMouseOut() {
    }
}

class DragActionEditPhysicalSurface {
    dragMouseMove(event) {
        if (this.dummyMove) {
            this.dummyMove.cancel();
            this.dummyMove = null;
        }

        const clientPt = containerPoint(this.dRenderer, event);
        this.snapAngles = event.shiftKey;
        this.computeEditGeometry(clientPt);
        this.redrawInstance();

        this.dummyMove = autoPanCamera(this.dRenderer, clientPt,
            () => {
                this.dragMouseMove(event);
            });
    }

    dragMouseOut() {
    }

    dragMouseUp(event) {
        const clientPt = containerPoint(this.dRenderer, event);
        this.computeEditGeometry(clientPt);
        publishUpdatePath(this.dRenderer, this.editData.object, this.editData.objectType, this.newPath);

        this.newDrawTopPath = null;
        this.redrawInstance();
    }

    computeEditGeometry(clientPt) {
        const ray = interactRay(this.dRenderer, clientPt);
        if (!ray) return;

        const ps = this.editData.object;
        const rayPoint = raySurfaceIntersect(ps, ray, true);

        const { newPath } = this;
        const { pathIndex } = this.editData;

        const adjPoint = this.snapAngles ?
            computeAnglePointSnap(
                rayPoint,
                arrayItemWrap(newPath, pathIndex - 1), arrayItemWrap(newPath, pathIndex - 2),
                arrayItemWrap(newPath, pathIndex + 1), arrayItemWrap(newPath, pathIndex + 2),
                snapDistance(this.dRenderer))
            : Vector.fromObject(rayPoint);

        newPath[pathIndex] = new THREE.Vector3(adjPoint.x, adjPoint.y, 0);
        this.newDrawTopPath = _.map(newPath, pt => ps.pointOnSurface(Vector.fromObject(pt)));

        const otherSurfaces = _.difference(this.dRenderer.design.physicalSurfaces(), [ps]);
        const parentPS = bestParentSurface(newPath, otherSurfaces);
        if (parentPS) {
            this.newDrawBasePath = _.map(newPath, pt => parentPS.pointOnSurface(Vector.fromObject(pt)));
        } else {
            this.newDrawBasePath = _.map(newPath, pt => new Vector(pt.x, pt.y, 0));
        }

        this.newDrawPathGround = _.map(newPath, pt => new Vector(pt.x, pt.y, 0));
    }

    redrawInstance() {
        // TODO: MT: visual feedback need to be thought out here
        if (this.editPrimitive) {
            this.editPrimitive.clearInstances();
            this.editPrimitive = null;
            this.dRenderer.dirtyFrame();
        }

        if (this.newDrawTopPath) {
            const dummyGeometry = {
                base_3d: this.newDrawBasePath,
                path_3d: this.newDrawTopPath,
                path: this.newDrawPathGround,
            };

            const scene = this.dRenderer.physicalSurfaceLayer;

            const wirePoints = makePhysicalSurfaceSegmentPoints(dummyGeometry);
            const wireGeometry = makeWireGeometry(wirePoints);
            const wireMaterial = this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire');

            const wireOptions = _.assign({
                geometry: wireGeometry,
                material: wireMaterial,
                depthOffset: this.dRenderer.tinyZOffset,
                strokeWeight: 2.0,
                strokeColor: '#ffaa44',
                fillOpacity: 0.0,
                scene,
            });

            this.editPrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, wireOptions);
        }
    }
}

export class DragActionEditPhysicalSurfaceMoveVertex extends DragActionEditPhysicalSurface {
    constructor(dRenderer, event, editData) {
        super();

        this.dRenderer = dRenderer;
        this.editData = _.assign({}, editData);

        const { geometry } = this.editData.object;

        // move vertex
        this.newPath = _.map(geometry.path, i => (new THREE.Vector3()).copy(i));
    }
}

export class DragActionEditPhysicalSurfaceCreateVertex extends DragActionEditPhysicalSurface {
    constructor(dRenderer, event, editData) {
        super();

        this.dRenderer = dRenderer;
        this.editData = _.assign({}, editData);

        const { geometry } = this.editData.object;

        // add vertex
        const pta = arrayItemWrap(geometry.path_3d, this.editData.pathIndex);
        const ptb = arrayItemWrap(geometry.path_3d, this.editData.pathIndex + 1);

        this.originalPoint =
            (new THREE.Vector3())
                .addVectors(pta, ptb)
                .multiplyScalar(0.5);

        // insert actual new point into path and set new point as our drag point
        this.newPath = _.map(geometry.path, i => (new THREE.Vector3()).copy(i));
        this.newPath.splice(this.editData.pathIndex + 1, 0,
            new Vector(this.originalPoint.x, this.originalPoint.y, 0));

        this.editData.pathIndex++;
    }
}

export class DragActionPremadeChangeProperty {
    constructor(renderer, event, editData) {
        this.renderer = renderer;
        this.premade = editData.object;
        this.planeWorldPoint = editData.worldPoint;
        this.direction = editData.direction;
        this.property = editData.property;
        this.minValue = editData.minValue;
        this.maxValue = editData.maxValue;

        this.downWorldPoint = this.dragPlanePoint(event);
    }

    dragMouseMove(event) {
        if (!this.downWorldPoint) return;

        const { newVal } = this.propertyValues(this.getDelta(event));

        const tempParams = _.cloneDeep(this.premade.geometry.parameters);
        const dummyEntity = { geometry: { parameters: tempParams } };
        dummyEntity.proxyProperties = this.premade.getProxyPropertyObject(dummyEntity);
        _.set(dummyEntity, this.property, newVal);

        const renderable = this.renderer.objectRenderMap.get(this.premade);
        if (renderable) {
            renderable.setParameterOverrides(tempParams);
            renderable.clearRenderable();
            renderable.renderRenderable();
        }
    }

    dragMouseUp(event) {
        if (!this.downWorldPoint) return;

        const renderable = this.renderer.objectRenderMap.get(this.premade);
        if (renderable) renderable.setParameterOverrides();

        const { oldVal, newVal } = this.propertyValues(this.getDelta(event));

        this.renderer.dispatcher.createSinglePropertyChange({
            resource: this.premade,
            path: `${this.property}`,
            oldVal,
            newVal,
            mergeable: true,
            loadMessage: `Change ${this.premade} ${this.property}`,
            rollbackMessage: `Undo change ${this.premade} ${this.property}`,
        });
    }

    dragMouseOut() {
    }

    dragPlanePoint(event) {
        const dragPlane = Plane.fromPointNormal(this.planeWorldPoint, this.renderer.cameraWorldForward);

        const pt = containerPoint(this.renderer, event);
        const { origin, dir } = interactRay(this.renderer, pt, 0.0);

        return dragPlane.intersectRay(Vector.fromObject(origin), Vector.fromObject(dir));
    }

    getDelta(event) {
        const dragPoint = this.dragPlanePoint(event);
        const delta = (new THREE.Vector3()).subVectors(dragPoint, this.downWorldPoint);

        if (this.direction === 'right') {
            return delta.dot(this.renderer.cameraWorldRight);
        } else if (this.direction === 'up') {
            return delta.dot(this.renderer.cameraWorldUp);
        }

        return 0;
    }

    propertyValues(delta) {
        const oldVal = _.get(this.premade, this.property);
        const newVal = _.clamp(oldVal + delta, this.minValue, this.maxValue);
        return { oldVal, newVal };
    }
}

export class DragActionPremadeMove {
    constructor(renderer, event, glObject) {
        this.renderer = renderer;
        this.interactData = glObject.userData.interactData;

        const pt = containerPoint(this.renderer, event);
        const gp = interactGroundPoint(this.renderer, pt);

        this.downGroundPoint = gp;

        this.moveEntity = this.interactData.object;

        if (event.altKey) {
            this.moveEntity = this.moveEntity.cloneEntity();
            this.moveClone = true;
            this.renderer.renderPremade(this.moveEntity);
        }

        const renderable = this.renderer.objectRenderMap.get(this.moveEntity);
        if (renderable) {
            renderable.updateRenderable(_.assign({}, renderable.options, { dragging: true }));
        }
    }

    handleCursorStyle(event) {
        if (event.altKey) {
            this.renderer.setCursorStyle(CursorConfig.DUPLICATE);
        } else {
            this.renderer.setCursorStyle(CursorConfig.MOVE);
        }
    }

    handleDummyMove() {
        if (this.dummyMove) {
            this.dummyMove.cancel();
            this.dummyMove = null;
        }
    }

    getPtAndDelta(event) {
        const pt = containerPoint(this.renderer, event);
        const gp = interactGroundPoint(this.renderer, pt);
        const delta = (new THREE.Vector3()).subVectors(gp, this.downGroundPoint);
        return { pt, delta };
    }

    renderAtNewPosition(entity, delta, pt, event) {
        const renderable = this.renderer.objectRenderMap.get(entity);
        if (renderable) {
            renderable.offsetRenderablePosition(delta);
            this.renderer.dirtyFrame();

            this.dummyMove = autoPanCamera(this.renderer, pt,
                () => {
                    this.dragMouseMove(event);
                });
        }
    }

    dragMouseMove(event) {
        this.handleCursorStyle(event);
        if (!this.downGroundPoint) return;

        this.handleDummyMove();

        const { pt, delta } = this.getPtAndDelta(event);

        const selectedEntities = this.renderer.dispatcher.selectedEntities;
        const dragEntity = this.moveEntity;
        if (selectedEntities.has(dragEntity)) {
            for (const entity of selectedEntities) {
                this.renderAtNewPosition(entity, delta, pt, event);
            }
        } else {
            this.renderAtNewPosition(dragEntity, delta, pt, event);
        }
    }

    cloneEntity(event) {
        if (this.downGroundPoint) {
            const { delta } = this.getPtAndDelta(event);
            const geometry = _.assign({}, this.moveEntity.geometry);
            const { position } = geometry.parameters;
            geometry.parameters.position = new Vector(position.x + delta.x, position.y + delta.y);

            actionPremadeCreate({
                dispatcher: this.renderer.dispatcher,
                geometry,
                cloneSource: this.moveEntity,
            });
        }

        this.renderer.clearPremade(this.moveEntity);
    }

    movePremade(event) {
        if (!this.downGroundPoint) return;

        const { delta } = this.getPtAndDelta(event);

        const selectedEntities = this.renderer.dispatcher.selectedEntities;
        const dragEntity = this.moveEntity;
        if (selectedEntities.has(dragEntity) && selectedEntities.size > 1) {
            moveMultipleEntities(this.renderer, selectedEntities, new Vector(delta.x, delta.y, delta.z));
        } else {
            moveEntityPosition(this.renderer, dragEntity, delta);
        }
    }

    dragMouseUp(event) {
        this.renderer.setCursorStyle(CursorConfig.DEFAULT);
        if (this.moveClone) {
            this.cloneEntity(event);
        } else {
            this.movePremade(event);
        }
    }

    dragMouseOut() {
    }
}

export class InteractToolCreatePhysicalSurface {
    constructor(dRenderer, promise) {
        this.dRenderer = dRenderer;
        this.promise = promise;
        this.processMouseUp = true;
        this.cursorHelper = new SurfaceCursorHelper(dRenderer);
        this.pasteAction = new PasteAction(dRenderer);
        this.initCursorRendering();
        this.dRenderer.dispatcher.deselectEntity();
    }

    initCursorRendering() {
        const surfaces = this.dRenderer.dispatcher.internalClipboard.readFromClipboard()
        if (this.dRenderer.dispatcher.isOnPasteMode) {
            this.cursorHelper.renderSurfacesAtCursor(surfaces, this.dRenderer.currentMousePosition)
            this.cursorHelper.clearCreateCursor();
            return true;
        }
        return false;
    }

    toolMouseDown(event) {
        const pt = containerPoint(this.dRenderer, event);
        this.panDownEvent = event;
        this.downPoint = pt;
        this.validAction = event.button === 0;
    }

    toolMouseUp(event) {
        const orgDownPoint = this.downPoint;
        this.downPoint = null;
        this.panDownEvent = null;

        if (this.dRenderer.dispatcher.isOnPasteMode) {
            this.pasteAction.pasteToCursor(orgDownPoint);
            return;
        }

        if (event.button !== 0 || !this.validAction) return;

        this.computeCreateGeometry(orgDownPoint);
        const createPoint = this.cursorHelper.cursorTopPoint || this.cursorHelper.cursorGroundPoint;

        if (createPoint && this.processMouseUp) {
            this.processMouseUp = false;
            if (!this.createPath) {
                // new path
                this.createPath = [
                    (new THREE.Vector3()).copy(createPoint),
                    (new THREE.Vector3()).copy(createPoint),
                ];
                if (this.promise) {
                    this.promise.notify(correctOrientation(toGeometryUtilVectorPath2D(this.createPath)));
                }
            } else {
                if (this.snapClose) {
                    // close path
                    this.closePath();
                } else {
                    // new point
                    if ((new THREE.Vector3()).subVectors(
                        this.createPath[this.createPath.length - 2], createPoint).length()) {
                        this.createPath[this.createPath.length - 1] = (new THREE.Vector3()).copy(createPoint);
                        this.createPath.push((new THREE.Vector3()).copy(createPoint));
                        if (this.promise) {
                            this.promise.notify(correctOrientation(toGeometryUtilVectorPath2D(this.createPath)));
                        }
                    }
                }
            }
        }

        this.redrawInstance();
    }

    toolMouseMove(event) {
        const pt = containerPoint(this.dRenderer, event);

        if (this.initCursorRendering()) {
            return;
        }

        if (this.panDownEvent) {
            if (panThreshold(this.downPoint, pt)) {
                if (event.shiftKey) {
                    this.dRenderer.activateDragAction(new DragActionCameraRotate(this.dRenderer, this.panDownEvent));
                } else {
                    this.dRenderer.activateDragAction(new DragActionCameraPan(this.dRenderer, this.panDownEvent));
                }
                this.cursorHelper.clearCreateCursor();
                this.panDownEvent = null;
                return;
            }
        }

        this.snapAngles = event.shiftKey;
        this.computeCreateGeometry(pt);
        this.cursorHelper.redrawCreateCursor();

        this.processMouseUp = true;
        this.redrawInstance();
    }

    toolMouseOut() {
        this.cursorHelper.clearCreateCursor();
    }

    toolMouseWheel(event) {
        zoomView(this.dRenderer, event);
    }

    toolDblClick() {
        if (!this.validAction) return;
        if (!this.createPath || this.createPath.length < 3) return;
        this.closePath();
    }

    deactivateTool() {
        this.cursorHelper.clearCreateCursor();
        this.cursorHelper.clearSurfaceCursorPrimitives();
        this.clearCreation();
        if (this.promise) {
            this.promise.reject();
        }
    }

    clearCreation() {
        this.createPath = null;
        this.redrawInstance();
    }

    closePath() {
        if (this.promise) {
            this.createPath.pop();
            if (this.createPath.length < 2) {
                this.promise.reject();
            } else {
                this.promise.resolve(correctOrientation(toGeometryUtilVectorPath2D(this.createPath)));
            }
            this.createPath = null;
        }
    }

    highestSurfacePoint(ray) {
        let maxPoint = rayGroundPlaneIntersect(ray);
        for (const ps of this.dRenderer.design.physicalSurfaces()) {
            const surfacePoint = raySurfaceIntersect(ps, ray);
            if (surfacePoint && surfacePoint.z > maxPoint.z) {
                maxPoint = surfacePoint;
            }
        }

        return maxPoint;
    }

    computeClosePathSnap(ray) {
        if (this.createPath.length <= 2) {
            this.snapClose = false;
            return null;
        }

        const closePath = _.map(this.createPath, pt => (new THREE.Vector3()).copy(pt));
        closePath.pop();
        const closePS = bestParentSurface(closePath, this.dRenderer.design.physicalSurfaces());
        const closePoint = closePS ?
            closePS.pointOnSurface(Vector.fromObject(closePath[0])) : new Vector(closePath[0].x, closePath[0].y, 0);
        const mouseTestPoint = closePS ?
            raySurfaceIntersect(closePS, ray, true) : rayGroundPlaneIntersect(ray);

        const closeDistance = snapDistance(this.dRenderer);
        this.snapClose = (new THREE.Vector3()).subVectors(closePoint, mouseTestPoint).length() < closeDistance;
        return closePS;
    }

    computeCreateAnglePointSnap(currPoint) {
        // snap to immediately preceding edge by angle
        const snapAdjOrigin = this.createPath[this.createPath.length - 2];
        let snapAdjDir = new THREE.Vector3(0, 1, 0);
        if (this.createPath.length > 2) {
            snapAdjDir = (new THREE.Vector3())
                .subVectors(snapAdjOrigin, this.createPath[this.createPath.length - 3]);
        }
        const adjVector = snapVector(snapAdjOrigin, snapAdjDir, currPoint);
        const adjSnapPoint = (new THREE.Vector3()).addVectors(snapAdjOrigin, adjVector);
        if (this.createPath.length < 3) return adjSnapPoint;

        const snapAdjA = snapAdjOrigin;
        const snapAdjB = this.createPath[this.createPath.length - 3];
        const snapCloseA = this.createPath[0];
        const snapCloseB = this.createPath[1];

        return computeAnglePointSnap(
            currPoint,
            snapAdjA, snapAdjB, snapCloseA, snapCloseB,
            snapDistance(this.dRenderer));
    }

    computeCreateGeometry(clientPt) {
        this.cursorHelper.clearCursorPosition();

        const ray = interactRay(this.dRenderer, clientPt);
        if (!ray) return;

        if (!this.createPath) {
            this.cursorHelper.computeCursorPosition(clientPt);
        } else {
            const closePS = this.computeClosePathSnap(ray);

            let parentPS = null;
            if (this.snapClose) {
                this.createPath[this.createPath.length - 1] = (new THREE.Vector3()).copy(this.createPath[0]);
                parentPS = closePS;
            } else {
                const allSurfaces = this.dRenderer.design.physicalSurfaces();
                let bestPS = bestParentSurfaceRay(this.createPath, ray, allSurfaces);
                let currPoint = bestPS ?
                    Vector.fromObject(raySurfaceIntersect(bestPS, ray)) : rayGroundPlaneIntersect(ray);
                if (this.snapAngles) {
                    currPoint = this.computeCreateAnglePointSnap(currPoint);
                    this.createPath[this.createPath.length - 1] = currPoint;
                    // it's possible that snapping changes parent surface -- so recompute
                    bestPS = bestParentSurface(this.createPath, allSurfaces);
                }
                this.createPath[this.createPath.length - 1] = currPoint;
                parentPS = bestPS;
            }

            if (parentPS) {
                this.createPath = _.map(this.createPath,
                    pt => (new THREE.Vector3()).copy(parentPS.pointOnSurface(Vector.fromObject(pt))));
            } else {
                this.createPath = _.map(this.createPath,
                    pt => new THREE.Vector3(pt.x, pt.y, 0));
            }

            this.cursorHelper.forceCursorPosition(this.createPath[this.createPath.length - 1]);
        }
    }

    redrawInstance() {
        if (this.editPrimitive) {
            this.editPrimitive.clearInstances();
            this.editPrimitive = null;
            this.dRenderer.dirtyFrame();
        }

        if (this.dummyWidget) {
            this.dummyWidget.clearWidget();
            this.dummyWidget = null;
        }

        if (this.createPath) {
            const color = this.snapClose ?
                RendererOptions.createPathOptions.closePathColor :
                RendererOptions.createPathOptions.openPathColor;

            const dummyPath = correctOrientation(toGeometryUtilVectorPath3D(this.createPath));

            const dummyGeometry = {
                base_3d: dummyPath,
                path_3d: dummyPath,
                path: dummyPath,
            };

            const wirePoints = makePhysicalSurfaceSegmentPoints(dummyGeometry);
            const wireGeometry = makeWireGeometry(wirePoints);
            const wireMaterial = this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire');

            const wireOptions = _.assign({
                geometry: wireGeometry,
                material: wireMaterial,
                depthOffset: this.dRenderer.tinyZOffset,
                strokeWeight: RendererOptions.createPathOptions.pathWeight,
                strokeColor: color,
                fillOpacity: 0.0,
                scene: this.dRenderer.physicalSurfaceLayer,
            });

            this.editPrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, wireOptions);

            const dummyPS = new PhysicalSurface();
            dummyPS.geometry = dummyGeometry;

            this.dummyWidget = new WidgetSurfaceCollection(this.dRenderer, dummyPS, 'FieldSegment');
            this.dummyWidget.createWidget({ edgeLabels: true });
        }
    }
}

export class InteractToolCreatePremade {
    constructor(dRenderer) {
        this.dRenderer = dRenderer;
        this.cursorHelper = new SurfaceCursorHelper(dRenderer);
        this.pasteAction = new PasteAction(dRenderer);
        this.initCursorRendering();

        this.newPremade = null;
        this.dRenderer.dispatcher.deselectEntity();
    }

    initCursorRendering() {
        const surfaces = this.dRenderer.dispatcher.internalClipboard.readFromClipboard()
        if (this.dRenderer.dispatcher.isOnPasteMode) {
            this.cursorHelper.renderSurfacesAtCursor(surfaces, this.dRenderer.currentMousePosition)
            this.cursorHelper.clearCreateCursor();
            return true;
        }
        return false;
    }

    toolMouseDown(event) {
        const pt = containerPoint(this.dRenderer, event);
        this.panDownEvent = event;
        this.downPoint = pt;
        this.validAction = event.button === 0;
    }

    toolMouseUp(event) {
        const orgDownPoint = this.downPoint;
        this.downPoint = null;
        this.panDownEvent = null;

        if (this.dRenderer.dispatcher.isOnPasteMode) {
            this.pasteAction.pasteToCursor(orgDownPoint);
            return;
        }

        if (event.button !== 0 || !this.validAction) return;

        this.computeCreateGeometry(orgDownPoint);
        const createPoint = this.cursorHelper.cursorTopPoint || this.cursorHelper.cursorGroundPoint;

        if (createPoint) {
            if (!this.newPremade) {
                const last = _.maxBy(this.dRenderer.dispatcher.design.entity_premades, 'entity_premade_id');
                const params = last ? last.geometry.parameters : { top_radius: 2.5, bot_radius: 0.25, bot_height: 2.5 };

                this.newPremade = new EntityPremade({
                    geometry: {
                        premade_type: 'tree_sphere',
                        parameters: {
                            top_radius: params.top_radius,
                            bot_radius: params.bot_radius,
                            bot_height: params.bot_height,
                            position: {
                                x: createPoint.x,
                                y: createPoint.y,
                                z: 0, // not used, but makes 2d distance work
                            },
                        },
                    },
                });

                this.dRenderer.renderPremade(this.newPremade, { renderOptions: { editable: false } });
            } else {
                actionPremadeCreate({
                    dispatcher: this.dRenderer.dispatcher,
                    geometry: this.newPremade.geometry,
                });

                this.dRenderer.clearPremade(this.newPremade);
                this.newPremade = null;
            }
        }

        this.redrawInstance();
    }

    toolMouseMove(event) {
        const pt = containerPoint(this.dRenderer, event);

        if (this.initCursorRendering()) {
            return;
        }

        if (this.panDownEvent) {
            if (panThreshold(this.downPoint, pt)) {
                if (event.shiftKey) {
                    this.dRenderer.activateDragAction(new DragActionCameraRotate(this.dRenderer, this.panDownEvent));
                } else {
                    this.dRenderer.activateDragAction(new DragActionCameraPan(this.dRenderer, this.panDownEvent));
                }
                this.cursorHelper.clearCreateCursor();
                this.panDownEvent = null;
                return;
            }
        }

        if (this.newPremade) {
            this.computeCreateGeometry(pt);
            const mousePoint = this.cursorHelper.cursorGroundPoint;

            // only works intuitively for top down
            const topRadius = (new THREE.Vector2()).subVectors(mousePoint, this.newPremade.geometry.parameters.position).length();

            if (topRadius > EntityPremade.MIN_TOP_RADIUS) { // gives some leeway for double clicking to create the same tree as before
                this.newPremade.geometry.parameters.top_radius = Math.min(EntityPremade.MAX_TOP_RADIUS, topRadius);
                this.dRenderer.renderPremade(this.newPremade, { renderOptions: { editable: false } });
            }
        }

        this.computeCreateGeometry(pt);
        this.cursorHelper.redrawCreateCursor();

        this.processMouseUp = true;
        this.redrawInstance();
    }

    toolMouseOut() {
        this.cursorHelper.clearCreateCursor();
    }

    toolMouseWheel(event) {
        zoomView(this.dRenderer, event);
    }

    toolDblClick() {
    }

    deactivateTool() {
        this.cursorHelper.clearCreateCursor();
        this.cursorHelper.clearSurfaceCursorPrimitives();
        this.clearCreation();
    }

    clearCreation() {
        this.redrawInstance();

        if (this.newPremade) {
            this.dRenderer.clearPremade(this.newPremade);
        }
    }

    highestSurfacePoint(ray) {
        let maxPoint = rayGroundPlaneIntersect(ray);
        for (const ps of this.dRenderer.design.physicalSurfaces()) {
            const surfacePoint = raySurfaceIntersect(ps, ray);
            if (surfacePoint && surfacePoint.z > maxPoint.z) {
                maxPoint = surfacePoint;
            }
        }

        return maxPoint;
    }

    computeCreateGeometry(clientPt) {
        this.cursorHelper.computeCursorPosition(clientPt);
    }

    redrawInstance() {
    }
}
